.floors {
   .floors__title {
      text-align: center;
      font-size: 14px;
      margin: 0 auto 20px;
      max-width: 250px;
      @media (min-width: 768px) {
         max-width: 350px;
         font-size: 16px;
         margin: 20px auto 30px;
      }
      @media (min-width: 1200px) {
         max-width: 650px;
         font-size: 20px;
         margin: 10px auto 40px;
      }
   }
   .floors__image {
      height: 115px;
      max-width: 330px;
      position: relative;

      .floors__item {
         position: absolute;
         z-index: 1;
         top: 0;
         left: 0;
         background-color: #fff;
         box-shadow: 0px 3px 6px #00000057;
         padding: 15px;
         width: 185px;
         opacity: 0;
         pointer-events: none;

         &.active {
            opacity: 1;
         }

         .floors__item-title {
            font-family: "Limelight";
            font-size: 18px;
            text-align: center;
            margin-bottom: 15px;
         }

         .floors__item-text {
            display: flex;
            justify-content: space-between;
            max-width: 185px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 8px;
            font-size: 12px;

            span {
               margin-left: 10px;
               display: inline-block;
            }
         }
      }
   }
}

@media (max-width: 1199px) {
   .floors {
      .container {
         max-width: 100%;
         margin: 0;
         padding: 0;
      }
   }
}

@media (min-width: 768px) {
   .floors {
      .floors__image {
         height: 250px;
         max-width: 768px;

         .floors__item {
            width: 285px;

            .floors__item-title {
               font-size: 20px;
            }

            .floors__item-text {
               font-size: 14px;
            }
         }
      }
   }
}

@media (min-width: 1200px) {
   .floors {
      .floors__image {
         height: 320px;
         max-width: 985px;
      }
   }
}

@media (min-width: 1780px) {
   .floors {
      .floors__image {
         height: 500px;
         max-width: 100%;
      }
   }
}
