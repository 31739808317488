.investor {
   .investor__box {
      background-color: var(--s7-color-green);
      padding-top: 30px;
      padding-bottom: 85px;

      .investor__logo {
         max-width: 100%;
         margin-bottom: 25px;
         img {
            width: 150px;
            height: 50px;
            margin-left: auto;
            margin-right: auto;
            display: block;
         }
      }

      .slider {
         .slider__container--investor[data-index="0"] {
            height: 150px;
         }

         .slider__container--investor[data-index="1"] {
            height: 165px;
         }
      }
   }
}

@media (min-width: 550px) {
   .investor {
      .investor__box {
         .investor__logo {
            max-width: 250px;
            margin-left: auto;
            margin-right: auto;
         }

         .slider {
            .slider__container--investor[data-index="0"] {
               height: 105px;
            }

            .slider__container--investor[data-index="1"] {
               height: 105px;
            }
         }
      }
   }
}

@media (min-width: 1200px) {
   .investor {
      padding-top: 145px;

      .investor__box {
         padding-top: 30px;
         padding-bottom: 60px;
         max-width: 1536px;
         margin-left: auto;
         margin-right: auto;

         .investor__logo {
            img {
               width: 300px;
               height: 150px;
            }
         }

         h2 {
            text-align: center;
            padding: 0;
            margin: 0 0 20px;
         }

         .slider {
            max-width: 760px;
            margin-left: auto;
            margin-right: auto;
            padding-top: 30px;

            .app__text-box {
               justify-content: center;
               .app__text {
                  text-align: center;
                  padding-left: 0px;
                  padding-right: 0;
               }
            }

            .slider__container--investor {
               height: auto !important;
            }
         }

         .slider-arrows {
            width: 900px;
            left: 50%;
            right: auto;
            top: 62%;
            transform: translate(-50%, -50%);
         }

         .line {
            height: 9px;
            width: 344px;
            left: 50%;
            transform: translateX(-50%);
            position: relative;
            background-color: transparent;

            &:before {
               content: "";
               width: 0;
               height: 100%;
               position: absolute;
               background-color: #000;
               display: block;
               left: 0;
               top: 0;
               transition: inherit;
            }
         }
      }
      &.anim {
         .line {
            width: 344px;
            height: 9px;

            &:before {
               width: 100%;
            }
         }
      }
   }
}

@media (min-width: 1400px) {
   .investor {
      .investor__box {
         .investor__logo {
            max-width: 500px;
         }
         .slider {
            max-width: 960px;
         }
         .slider-arrows {
            width: 1000px;
         }
      }
   }
}
