.imageSlider {
   position: relative;
   .imageSlider__image {
      height: 300px;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
   }

   .slider {
      .slider-controls {
         position: absolute;
         bottom: 20px;
         left: 50%;
         transform: translateX(-50%);
      }

      .slider-dots__dot {
         background-color: #fff !important;
      }
   }

   .slider-arrows {
      width: 330px;

      .slider-arrows__arrow {
         width: 20.5px;
         height: 20px;

         &.arrow--prev {
            background-image: url("../images/icons/ico-larrow-white.svg");
         }

         &.arrow--next {
            background-image: url("../images/icons/ico-rarrow-white.svg");
         }
      }
   }
}

@media (min-width: 768px) {
   .imageSlider {
      .imageSlider__image {
         height: 400px;
      }

      .slider-arrows {
         width: 650px;
      }
   }
}
