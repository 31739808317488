.offer-results {
  padding-bottom: 50px;

  .info {
    margin-top: 2.5rem;
    font-size: 1rem;
    color: red;
    text-align: center;
  }

  .offer-results__item {
    padding: 50px 0;
    position: relative;
    text-align: center;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      right: 0;
      width: 90vw;
      max-width: 690px;
      transform: translateX(-50%);
      height: 1px;
      background-color: #000;
    }

    @media (min-width: 768px) {
      display: flex;
      align-items: center;
      padding: 80px 0 40px;

      &:after {
        display: none;
      }
    }

    @media (min-width: 1200px) {
      padding: 175px 0 60px;
    }

    .offer-results__item-col-left {
      .offer-results__item-title {
        text-align: center;
        font-size: 14px;
        margin-bottom: 30px;

        b {
          margin-left: 10px;
          font-size: 12px;
        }

        @media (min-width: 576px) {
          font-size: 16px;

          b {
            font-size: 14px;
          }
        }

        @media (min-width: 768px) {
          font-size: 30px;
          margin-bottom: 40px;

          b {
            font-size: 20px;
            margin-left: 30px;
          }
        }

        @media (min-width: 1200px) {
          font-size: 50px;

          b {
            font-size: 30px;
            margin-left: 50px;
          }
        }
      }

      .offer-results__item-image {
        width: 100%;
        max-width: 250px;
        height: 170px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100%;
        margin-bottom: 20px;
        margin-left: auto;
        margin-right: auto;

        @media (min-width: 576px) {
          max-width: 350px;
          height: 220px;
        }

        @media (min-width: 768px) {
          max-width: 100%;
          margin-bottom: 0;
        }

        @media (min-width: 1200px) {
          max-width: 480px;
          height: 330px;
        }
      }

      @media (min-width: 768px) {
        flex-grow: 1;
      }

      @media (min-width: 1200px) {
        margin-right: 95px;
      }
    }

    .offer-results__item-data {
      .offer-results__item-data-item {
        margin-bottom: 13px;
        padding-bottom: 5px;
        border-bottom: 1px solid #000;
        font-size: 12px;

        &.red {
          color: red;
        }

        b {
          margin-left: 10px;
          font-size: 12px;
        }

        @media (min-width: 576px) {
          margin-bottom: 15px;
          padding-bottom: 10px;
          font-size: 14px;

          b {
            font-size: 14px;
          }
        }

        @media (min-width: 768px) {
          margin-bottom: 20px;
          text-align: left;
        }

        @media (min-width: 1200px) {
          margin-bottom: 35px;
          font-size: 16px;
          border-bottom: none;
          position: relative;

          &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            right: 0;
            height: 1.5px;
            background-color: #000;
            width: 50%;
          }

          b {
            font-size: 16px;
          }
        }
      }

      @media (min-width: 768px) {
        width: 30%;
        margin: 0 20px;
      }

      @media (min-width: 1200px) {
        width: 40%;
        margin: 0;
      }
    }

    .offer-results__item-data-summary {
      .offer-results__item-data-full-price {
        margin-bottom: 25px;
        margin-top: 45px;
        font-size: 15px;
        font-weight: bold;

        @media (min-width: 576px) {
          font-size: 16px;
        }

        @media (min-width: 768px) {
          margin-top: 0;
        }

        @media (min-width: 1200px) {
          margin-bottom: 30px;
          font-size: 30px;
        }
      }

      .offer-results__item-data-area-price {
        margin-bottom: 40px;

        .promo {
          color: red;
        }

        p {
          font-size: 11px;
          margin-bottom: 8px;
        }

        span {
          font-size: 12px;
          font-weight: 600;
          display: block;
        }

        @media (min-width: 576px) {
          p {
            font-size: 14px;
          }

          span {
            font-size: 15px;
          }
        }

        @media (min-width: 768px) {
          margin-bottom: 80px;
        }

        @media (min-width: 1200px) {
          margin-bottom: 215px;

          p {
            font-size: 16px;
          }

          span {
            font-size: 16px;
          }
        }
      }

      @media (min-width: 768px) {
        width: 25%;
      }

      @media (min-width: 1200px) {
        text-align: left;
      }
    }
  }

  .offer-results__show-more {
    font-size: 10px;
    margin-top: 25px;
    background-color: transparent;
    border: none;
    outline: none;
    font-family: "Limelight";
    cursor: pointer;

    @media (min-width: 576px) {
      font-size: 12px;
    }

    @media (min-width: 768px) {
      font-size: 14px;
    }

    @media (min-width: 1200px) {
      font-size: 26px;
      margin-top: 45px;
      margin-bottom: 60px;
    }
  }

  .offer-results__no-results {
    margin-top: 40px;
    font-size: 14px;

    @media (min-width: 768px) {
      margin-top: 40px;
      margin-bottom: 100px;
      font-size: 16px;
    }

    @media (min-width: 1200px) {
      margin-top: 60px;
      font-size: 20px;
    }
  }
}
