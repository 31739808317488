.policy {
   background-color: var(--s7-color-green);
   padding: 40px 0;

   .policy__top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;

      .policy__logo {
         max-width: 150px;
         margin-right: 20px;
      }

      .policy__title {
         margin-bottom: 0;
         font-size: 18px;
         color: #fff;
         text-shadow: 0 0 5px rgba($color: #000000, $alpha: 0.5);
      }
   }

   .policy__text-title {
      font-weight: bold;
      color: #fff;
      font-size: 14px;
      line-height: 1.5;
   }

   .policy__text {
      margin-bottom: 20px;
      color: #fff;
      font-size: 12px;
      line-height: 1.5;
      max-width: 100%;
      word-wrap: break-word;

      a {
         color: inherit;
      }

      &.policy__text--first {
         margin-top: 20px;
      }
   }
}

@media (max-width: 550px) {
   .policy {
      .container {
         max-width: 100%;
      }
   }
}

@media (min-width: 550px) {
   .policy {
      .policy__text-title {
         font-size: 16px;
         line-height: 1.6;
      }

      .policy__text {
         margin-bottom: 30px;
         font-size: 14px;
         line-height: 1.6;

         &.policy__text--first {
            margin-top: 30px;
         }
      }
   }
}

@media (min-width: 768px) {
   .policy {
      .policy__top {
         margin-bottom: 40px;

         .policy__logo {
            max-width: 250px;
         }

         .policy__title {
            font-size: 25px;
         }
      }
   }
}

@media (min-width: 1200px) {
   .policy {
      .policy__top {
         margin-bottom: 60px;

         .policy__logo {
            max-width: 350px;
         }

         .policy__title {
            font-size: 35px;
         }
      }

      .policy__text-title {
         font-size: 18px;
         line-height: 1.8;
      }

      .policy__text {
         margin-bottom: 35px;
         font-size: 16px;
         line-height: 1.8;

         &.policy__text--first {
            margin-top: 35px;
         }
      }
   }
}
