.imagesRow {
   height: 360px;
   margin-left: 192px;
   padding-left: 0;
   // margin-left: 192px;

   .imagesRow__image {
      height: 100%;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      opacity: 0;

      &:nth-of-type(1) {
         flex-grow: 1;
         transition: 0.8s 1.6s;
      }

      &:nth-of-type(2) {
         // width: 672px;
         min-width: 500px;
         max-width: 500px;
         transition: 0.8s 0.8s;
      }
   }

   &.anim-text {
      .imagesRow__image {
         opacity: 1;
      }
   }
}

@media (min-width: 1400px) {
   .imagesRow {
      height: 563px;

      .imagesRow__image {
         height: 100%;
         background-position: center center;
         background-size: cover;
         background-repeat: no-repeat;
         opacity: 0;

         &:nth-of-type(1) {
            flex-grow: 1;
            transition: 0.8s 1.6s;
         }

         &:nth-of-type(2) {
            width: 672px;
            transition: 0.8s 0.8s;
         }
      }

      &.anim-text {
         .imagesRow__image {
            opacity: 1;
         }
      }
   }
}
