.header--offer {
   border-bottom: none;
}

.offer__submit {
   border: 1.5px solid #000;
   background-color: transparent;
   outline: none;
   padding: 13px 45px;
   display: inline-block;
   margin-top: 10px;
   font-size: 10px;
   font-family: "Limelight";
   transition: 0.3s;
   cursor: pointer;

   &:hover {
      background-color: var(--s7-color-green);
      border: 1.5px solid var(--s7-color-green);
      color: #fff;
   }

   &--filter {
      &:hover {
         background-color: #fff;
         border: 1.5px solid #fff;
         color: var(--s7-color-green);
      }
   }

   @media (min-width: 576px) {
      margin-top: 25px;
      font-size: 14px;
   }

   @media (min-width: 768px) {
      font-size: 16px;
      padding: 12px 45px;

      &--filter {
         padding: 16px 50px;
      }
   }

   @media (min-width: 1200px) {
      padding: 18px 75px;
      border: 2px solid #000;

      &:hover {
         border: 2px solid var(--s7-color-green);
      }

      &--filter {
         margin-top: 65px;

         &:hover {
            border: 2px solid #fff;
         }
      }
   }
}

.header.header--offer {
   .header__right-col {
      .header__top {
         padding: 12px 15px 12px 35px;

         @media (min-width: 1200px) {
            padding: 12px 95px 12px 35px;
            border-bottom: 12px solid #000;
         }

         .header__top-mobile-menu-logo {
            width: 150px;
            height: 70px;
            margin-left: 0px;
         }

         .header__top-menu {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-grow: 1;
            margin-left: 50px;

            @media (min-width: 1400px) {
               margin-left: 150px;
            }

            ul {
               display: flex;
               justify-content: space-between;
               list-style: none;
               padding: 0;
               width: 60%;

               li {
                  font-size: 18px;
                  text-transform: uppercase;
                  position: relative;
                  cursor: pointer;
                  a {
                     color: #000;
                     text-decoration: none;
                  }

                  &:before {
                     content: "";
                     width: 0;
                     height: 5px;
                     background-color: #000;
                     left: 0px;
                     bottom: -5px;
                     transition: 0.3s;
                     position: absolute;
                     display: block;
                  }

                  &:hover {
                     &:before {
                        width: 100%;
                     }
                  }
               }

               @media (min-width: 1400px) {
                  width: 50%;
                  max-width: 760px;

                  li {
                     font-size: 20px;
                  }
               }
            }

            div {
               font-size: 18px;
               b {
                  margin-left: 8px;
               }

               @media (min-width: 1400px) {
                  font-size: 20px;
               }
            }
         }
      }

      .header__banner {
         &.header__banner--offer {
            height: 400px;
            background-position: center top -50px;
            background-image: url("../images/image5.jpg");
            position: relative;
            align-items: flex-start;
            padding-top: 25px;

            .header__banner-text {
               color: #fff;
               text-align: center;
            }

            @media (min-width: 1200px) {
               padding: 100px 0 80px;
               height: auto;
               background-image: none;
               display: block;
               z-index: 1;
               .header__banner-text {
                  font-size: 35px;
                  color: #000;
               }
            }
         }
      }
   }
}
