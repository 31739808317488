header {
   position: relative;
   overflow: hidden;

   .header__left-col {
      .header__menu {
         .header__menu-list {
            position: fixed;
            top: -100vh;
            left: 0;
            width: 100vw;
            height: 100vh;
            transition: 0.3s;
            z-index: 100;
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;

            &.active {
               top: 0;
            }

            .header__menu-list-close {
               font-size: 30px;
               font-family: "Montserrat";
               position: absolute;
               top: 20px;
               right: 20px;
               cursor: pointer;
            }

            ul {
               text-align: center;
               ul {
                  margin-bottom: 14px;

                  li {
                     margin-bottom: 6px;
                     font-size: 18px;
                     text-transform: uppercase;
                  }
               }

               li {
                  position: relative;
                  font-size: 20px;
                  margin-bottom: 14px;
                  font-family: "Limelight";
                  cursor: pointer;

                  &:before {
                     content: "";
                     width: 0;
                     height: 5px;
                     background-color: #000;
                     left: 0px;
                     bottom: -5px;
                     transition: 0.3s;
                     position: absolute;
                     display: block;
                  }

                  &:hover {
                     &:before {
                        width: 100%;
                     }
                  }

                  &:last-child {
                     margin-bottom: 0;
                  }

                  a {
                     color: inherit;
                     text-decoration: none;
                  }
               }
            }
         }
      }
   }

   .header__right-col {
      .header__top {
         // background-color: #eee;
         background-position: center center;
         background-size: cover;
         display: flex;
         justify-content: space-between;
         align-items: center;
         padding: 16px 12px;
         border-bottom: 7px solid #000;
         position: fixed;
         top: 0;
         left: 0;
         width: 100%;
         z-index: 2;
         transition: padding 0.2s;

         &.moved {
            padding: 5.5px 12px;
         }

         .header__top-mobile-menu-logo {
            width: 50px;
            height: 50px;
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat;
            margin-left: 20px;
         }

         .header__top-mobile-menu-icon {
            padding: 8px 0;
            line-height: 0;
            cursor: pointer;

            span {
               width: 25px;
               height: 4px;
               background-color: #000;
               display: inline-block;
               position: relative;

               &:before,
               &:after {
                  content: "";
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  left: 0;
                  background-color: inherit;
               }

               &:before {
                  top: -8px;
               }
               &:after {
                  bottom: -8px;
               }
            }
         }
      }

      .header__banner {
         margin-top: 89px;
         height: 675px;
         background-repeat: no-repeat;
         background-position: center right -180px;
         background-image: url("../images/image2.jpg");
         display: flex;
         justify-content: center;
         align-items: center;
         position: relative;
         z-index: -1;

         .header__banner-img {
            position: relative;
            z-index: 3;
            opacity: 1;
            transition: 0.5s;

            &.anim {
               opacity: 0;
            }
         }
      }

      .header__arrows {
         position: absolute;
         bottom: 20px;
         left: 50%;
         transform: translateX(-50%);
         cursor: pointer;

         img {
            width: 35px;
            height: 35px;

            fill: #fff;
         }
      }
   }
}

@media (min-width: 550px) {
   header {
      .header__right-col {
         .header__banner {
            background-position: center center;
         }
      }
   }
}

@media (min-width: 1200px) {
   header {
      border-bottom: 12px solid #000;

      .header__left-col {
         width: 384px;
         height: 1074px;
         background-color: var(--s7-color-green);

         .header__menu {
            padding-left: 96px;

            .header__menu-logo {
               width: 747px;
               height: 396px;
               background-repeat: no-repeat;
               background-position: center center;
               background-size: contain;
               position: relative;
               z-index: 10;
               margin-bottom: 91px;
               left: -96px;
            }

            .header__menu-list {
               position: initial;
               top: 0;
               width: auto;
               height: auto;
               background-color: transparent;
               justify-content: flex-start;

               &.active {
                  top: 0;
               }

               .header__menu-list-close {
                  display: none;
               }

               ul {
                  text-align: left;
                  margin-left: 27px;
                  ul {
                     margin-left: 30px;
                  }
               }
            }

            .header__menu-contact {
               margin-top: 110px;

               p {
                  font-size: 18px;
                  line-height: 30px;
                  color: #000;
                  margin-bottom: 45px;
               }

               a {
                  color: inherit;
                  text-decoration: none;

                  &:hover {
                     text-decoration: underline;
                  }
               }

               a.header--fb {
                  display: inline-block;
                  transition: 0.3s;
                  &:hover {
                     transform: scale(0.8);
                     text-decoration: none;
                  }
               }
            }
         }
      }

      .header__right-col {
         flex-grow: 1;
         .header__top {
            height: 126px;
            padding: 0;
            border-bottom: 12px solid #000;
            position: initial;

            &.moved {
               padding: 0;
            }

            .header__top-mobile-menu-icon {
               display: none;
            }
         }

         .header__banner {
            margin-top: 0;
            height: 948px;
            background-image: url("../images/image2_1.jpg");
            left: 0;
         }
      }

      .header__side-menu {
         position: fixed;
         left: 0;
         top: 0;
         z-index: 3;
         max-width: 192px;
         height: 100vh;
         flex-direction: column;
         align-items: center;
         justify-content: space-between;
         padding: 30px 32px;
         transition: 0.4s;

         &.hidden {
            left: -192px;
            pointer-events: none;
         }

         .header__side-menu-logo {
            width: 90px;
            height: 40px;
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat;
         }

         .header__side-menu-icon {
            padding: 17px 0;
            line-height: 0;
            cursor: pointer;

            span {
               width: 47px;
               height: 7px;
               background-color: #000;
               display: inline-block;
               position: relative;

               &:before,
               &:after {
                  content: "";
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  left: 0;
                  background-color: inherit;
               }

               &:before {
                  top: -17px;
               }
               &:after {
                  bottom: -17px;
               }
            }
         }

         .header__side-menu-list {
            position: absolute;
            left: -384px;
            height: 100%;
            top: 0;
            width: 384px;
            background-color: #fff;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            display: flex;
            padding: 80px 66px;
            pointer-events: all;
            transition: 0.3s;

            &:hover {
               left: 0;
            }

            .header__side-menu-list-logo {
               width: 90px;
               height: 40px;
               background-position: center center;
               background-size: contain;
               background-repeat: no-repeat;
            }

            ul {
               margin-left: 27px;

               ul {
                  margin-left: 30px;
               }

               ul {
                  margin-bottom: 14px;

                  li {
                     margin-bottom: 6px;
                     font-size: 18px;
                     text-transform: uppercase;
                  }
               }

               li {
                  position: relative;
                  font-size: 20px;
                  margin-bottom: 14px;
                  font-family: "Limelight";
                  cursor: pointer;

                  &:before {
                     content: "";
                     width: 0;
                     height: 5px;
                     background-color: #000;
                     left: 0px;
                     bottom: -5px;
                     transition: 0.3s;
                     position: absolute;
                     display: block;
                  }

                  &:hover {
                     &:before {
                        width: 100%;
                     }
                  }

                  &:last-child {
                     margin-bottom: 0;
                  }

                  a {
                     color: inherit;
                     text-decoration: none;
                  }
               }
            }

            .header__side-menu-info {
               margin-left: 27px;

               a {
                  color: inherit;
                  text-decoration: none;

                  &:hover {
                     text-decoration: underline;
                  }
               }
            }
         }

         &:hover .header__side-menu-list {
            left: 0;
         }

         &.hidden .header__side-menu-list {
            left: -384px;
         }

         .header__side-menu-info {
            max-width: 150px;
            transition: padding 0.5s;

            p {
               font-size: 18px;
               line-height: 30px;
               color: #000;
               margin-bottom: 35px;
            }

            a {
               color: inherit;
               text-decoration: none;

               &:hover {
                  text-decoration: underline;
               }
            }

            a.header--fb {
               display: inline-block;
               transition: 0.3s;

               &:hover {
                  transform: scale(0.8);
                  text-decoration: none;
               }
            }
         }

         &.move-bottom {
            & > .header__side-menu-info {
               padding-bottom: 270px;
            }
         }
      }
   }
}
