.prestige {
   padding-top: 50px;
   padding-bottom: 40px;
}

@media (min-width: 1200px) {
   .prestige {
      padding-top: 140px;
      padding-bottom: 150px;

      .col--small {
         height: 344px;

         h2 {
            padding-top: 130px;
         }
      }
   }
}
