.standard {
   padding-top: 35px;
   padding-bottom: 35px;

   .slider__container--standard[data-index="0"] {
      height: 130px;
   }

   .slider__container--standard[data-index="1"] {
      height: 310px;
   }

   .standard__image {
      width: 672px;
      height: 620px;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      display: none;
      opacity: 0;
      transition: 0.8s;
   }
   // .slider-container--standard {
   //    .app__text-box {
   //       padding-top: 15px;
   //    }
   // }

   // .container--images {
   //    max-width: 100%;
   //    margin-top: 50px;
   //    margin-bottom: 50px;
   //    padding: 0;
   //    width: 100%;
   //    overflow: hidden;

   //    .slider-arrows {
   //       width: 330px;
   //    }

   //    .slider-controls {
   //       position: absolute;
   //       bottom: 20px;
   //       left: 50%;
   //       transform: translateX(-50%);
   //    }

   //    .slider-dots__dot {
   //       background-color: #fff;
   //    }
   // }
}

@media (min-width: 550px) {
   .standard {
      .slider {
         .slider__container--standard[data-index="0"] {
            height: 85px;
         }

         .slider__container--standard[data-index="1"] {
            height: 185px;
         }
      }
   }
}

@media (min-width: 1200px) {
   .standard {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 0;

      .container {
         max-width: 100%;
         margin: 0 0 0 192px;
         padding: 0;
         display: flex;
         align-items: center;

         .row {
            margin: 0;
            flex-grow: 1;
         }
      }

      .standard__image {
         display: block;
         min-width: 500px;
         max-width: 500px;
      }

      h2 {
         text-align: center;
         padding: 0;
         margin: 0 0 30px;
      }

      .slider {
         max-width: 530px;
         margin-left: auto;
         margin-right: auto;
         padding-top: 50px;
         margin-bottom: 40px;

         .app__text-box {
            justify-content: center;
            .app__text {
               // text-align: center;
               padding-left: 40px;
               padding-right: 40px;
            }
         }

         .slider__container--standard {
            height: auto !important;
         }
      }

      .slider-arrows {
         width: 490px;
         left: 50%;
         right: auto;
         top: 59%;
         transform: translate(-50%, -50%);
      }

      .line {
         height: 9px;
         width: 344px;
         left: 50%;
         transform: translateX(-50%);
         position: relative;
         background-color: transparent;

         &:before {
            content: "";
            width: 0;
            height: 100%;
            position: absolute;
            background-color: #000;
            display: block;
            left: 0;
            top: 0;
            transition: inherit;
         }
      }

      &.anim {
         .line {
            width: 344px;
            height: 9px;

            &:before {
               width: 100%;
            }
         }
      }

      &.anim-text {
         .standard__image {
            opacity: 1;
         }
      }
   }
}

@media (min-width: 1250px) {
   .standard {
      .slider-arrows {
         width: 515px;
      }
   }
}

@media (min-width: 1450px) {
   .standard {
      .standard__image {
         height: 563px;
      }

      .slider {
         max-width: 570px;
         margin-bottom: 0;
         .app__text-box {
            .app__text {
               padding-left: 0;
               padding-right: 0;
            }
         }
      }

      .slider-arrows {
         width: 670px;
         top: 62%;
      }
   }
}

@media (min-width: 1600px) {
   .standard {
      .slider {
         max-width: 768px;
      }

      .slider-arrows {
         width: 850px;
      }
   }
}

// @media (min-width: 1200px) {
//    .investment {

//       .slider-container--investment[data-index="0"] {
//          height: 175px;
//       }

//       .slider-container--investment[data-index="1"] {
//          height: 175px;
//       }

//       .slider-container--investment {
//          margin-bottom: 0;
//       }

//       .slider-container > div {
//          max-width: 100%;

//          .app__text1 {
//             padding: 0;
//             padding-left: 106px;
//             max-width: 100%;
//             max-width: 874px;

//             &:before {
//                display: none;
//             }
//          }
//       }
//    }
// }
