.rectangle {
   height: 90px;
   background-position: center center;
   background-size: cover;

   .rectangle__text {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100%;

      .rectangle__text-primary {
         font-size: 15px;
         font-family: "Limelight";
         margin-bottom: 5px;
         text-transform: uppercase;
      }

      .rectangle__text-secondary {
         font-size: 12px;
      }
   }
}

@media (min-width: 1200px) {
   .rectangle {
      height: 270px;
      border-top: 12px solid #000;
      border-bottom: 12px solid #000;

      .rectangle__title {
         font-size: 50px;
         font-family: "Limelight";
         margin-right: 92px;
         padding-top: 53px;
      }

      .col--middle {
         border-left: 9px solid #000;
         border-right: 9px solid #000;
      }

      .rectangle__text {
         justify-content: flex-start;
         align-items: flex-start;
         padding-top: 66px;
         text-align: left;
         width: 350px;
         margin-left: auto;
         margin-right: auto;

         .rectangle__text-primary {
            font-size: 35px;
            margin-bottom: 17px;
         }

         .rectangle__text-secondary {
            font-size: 18px;
            font-weight: bold;
         }
      }

      .rectangle__fb {
         margin-left: 87px;
         padding-top: 72px;

         p {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 15px;
         }

         a {
            display: inline-block;
            transition: 0.3s;

            &:hover {
               transform: scale(0.9);
            }
         }
      }
   }
}
