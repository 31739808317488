.flat {
  padding-top: 55px;
  padding-bottom: 38px;

  .flat__btn {
    width: 100%;
    height: 32px;
    border: 0;
    outline: 0;
    background-color: var(--s7-color-green);
    color: #fff;
    font-size: 12px;
    font-family: "Limelight";
    transition: 0.3s;
    text-align: center;
    margin-top: 25px;
    display: block;
    line-height: 32px;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
      opacity: 0.9;
    }
  }

  .info-end {
    margin-top: 25px;

    img {
      margin: auto;
      display: block;
    }

    img:nth-of-type(2) {
      display: none;
    }
    img:nth-of-type(3) {
      display: none;
    }
  }
}

@media (min-width: 768px) {
  .flat {
    .info-end {
      margin-top: 30px;
      img:nth-of-type(1) {
        display: none;
      }
      img:nth-of-type(2) {
        display: block;
      }
    }
  }
}

@media (min-width: 1200px) {
  .flat {
    padding-top: 89px;
    padding-bottom: 100px;

    .col--small {
      height: 344px;

      h2 {
        padding-top: 130px;
      }
    }

    .flat__btn {
      height: 62px;
      margin-top: 35px;
      line-height: 62px;
      font-size: 20px;
    }

    .info-end {
      img:nth-of-type(2) {
        display: none;
      }
      img:nth-of-type(3) {
        display: block;
      }
    }
  }
}
