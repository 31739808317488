.floor-projection {
  margin-top: 70px;
  padding-left: 192px;

  .info {
    margin-bottom: 25px;
    font-size: 1.5rem;
    color: red;
  }

  .floor-projection__item {
    text-align: center;

    .floor-projection__item-title {
      font-size: 30px;
      display: flex;
      justify-content: space-between;
      padding: 0 85px;
      max-width: 90%;

      b:nth-of-type(2) {
        margin-right: 65px;
      }

      @media (min-width: 1400px) {
        padding: 0 125px;

        b:nth-of-type(2) {
          margin-right: 85px;
        }
      }
    }
    .floor-projection__item-image {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url("../images/flrs/0-pietro-white.png");
      width: 100%;
      height: 400px;
      cursor: pointer;
      position: relative;

      .floor-projection__item-image-flat {
        background-repeat: no-repeat;
        height: 55.5%;
        background-size: cover;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;
        // pointer-events: none;
        transition: 0.3s;

        &:hover {
          opacity: 1;
        }

        &:nth-child(14) {
          display: none;
        }
        &:nth-child(15) {
          display: none;
        }
        &:nth-child(16) {
          display: none;
        }

        &:nth-child(1) {
          width: 83px;
          background-position: left -42px center;
          left: 43px;
        }

        &:nth-child(2) {
          width: 78px;
          background-position: left -127px center;
          left: 129px;
        }

        &:nth-child(3) {
          width: 85px;
          background-position: left -312px top -8px;
          left: 308px;
          height: 85px;
          top: 138px;
          background-size: 1100%;
        }

        &:nth-child(4) {
          width: 85px;
          background-position: left -311px bottom;
          left: 307px;
          top: 247px;
          height: 133px;
          background-size: 1100%;
        }

        &:nth-child(5) {
          width: 80px;
          background-position: left -389px center;
          left: 394px;
        }

        &:nth-child(6) {
          width: 83px;
          background-position: left -568px top;
          left: 575px;
          top: 140px;
          height: 103px;
          background-size: 1100%;
        }

        &:nth-child(7) {
          width: 83px;
          background-position: left -568px bottom -8px;
          left: 575px;
          top: 248px;
          height: 110px;
          background-size: 1100%;
        }

        &:nth-child(8) {
          width: 76px;
          background-position: left -648px top;
          left: 657px;
          top: 131px;
          height: 85px;
          background-size: 1200%;
        }

        &:nth-child(9) {
          width: 73px;
          background-position: left -663px bottom;
          left: 658px;
          top: 263px;
          height: 99px;
          background-size: 1275%;
        }

        &:nth-child(10) {
          width: 84px;
          background-position: left -732px top;
          left: 732px;
          top: 131px;
          height: 85px;
          background-size: 1100%;
        }

        &:nth-child(11) {
          width: 84px;
          background-position: left -732px bottom;
          left: 732px;
          top: 261px;
          height: 103px;
          background-size: 1100%;
        }

        &:nth-child(12) {
          width: 84px;
          background-position: left -813px top;
          left: 813px;
          top: 143px;
          height: 110px;
          background-size: 1100%;
        }

        &:nth-child(13) {
          width: 84px;
          background-position: left -813px bottom;
          left: 813px;
          top: 255px;
          height: 115px;
          background-size: 1100%;
        }

        &:nth-child(14) {
          width: 82px;
          background-position: left -565px center;
          left: 572px;
        }

        &:nth-child(15) {
          width: 158px;
          background-position: left -659px top -7px;
          left: 658px;
          top: 129px;
          height: 68px;
          background-size: 586%;
        }

        &:nth-child(16) {
          width: 87px;
          background-position: left -303px center;
          left: 307px;
        }

        @media (min-width: 1400px) {
          height: 66%;

          &:nth-child(1) {
            width: 110px;
            background-size: 1000%;
          }

          &:nth-child(2) {
            width: 95px;
            background-position: left -149px center;
            left: 152px;
          }

          &:nth-child(3) {
            width: 102px;
            background-position: left -374px top;
            left: 367px;
            top: 118px;
            height: 107px;
          }

          &:nth-child(4) {
            width: 100px;
            background-position: left -366px bottom 4px;
            left: 367px;
            top: 257px;
            height: 162px;
          }

          &:nth-child(5) {
            width: 100px;
            background-position: left -459px center;
            left: 467px;
          }

          &:nth-child(6) {
            width: 99px;
            background-position: left -673px top;
            left: 683px;
            top: 128px;
            height: 123px;
          }

          &:nth-child(7) {
            width: 101px;
            background-position: left -689px bottom -12px;
            left: 684px;
            top: 258px;
            height: 130px;
          }

          &:nth-child(8) {
            width: 88px;
            background-position: left -785px top;
            left: 785px;
            top: 118px;
            height: 103px;
            background-size: 1255%;
          }

          &:nth-child(9) {
            width: 88px;
            background-position: left -782px bottom -15px;
            left: 785px;
            top: 268px;
            height: 100px;
            background-size: 1250%;
          }

          &:nth-child(10) {
            width: 93px;
            background-position: left -887px top;
            left: 877px;
            top: 117px;
            height: 103px;
            background-size: 1200%;
          }

          &:nth-child(11) {
            width: 93px;
            background-position: left -888px bottom -18px;
            left: 877px;
            top: 268px;
            height: 98px;
            background-size: 1200%;
          }

          &:nth-child(12) {
            width: 92px;
            background-position: left -973px top -21px;
            left: 973px;
            top: 142px;
            height: 109px;
            background-size: 1200%;
          }

          &:nth-child(13) {
            width: 100px;
            background-position: left -969px bottom 2px;
            left: 973px;
            top: 267px;
            height: 137px;
          }

          &:nth-child(14) {
            width: 129px;
            background-position: left -671px center;
            left: 683px;
          }

          &:nth-child(15) {
            width: 190px;
            background-position: left -793px top -10px;
            left: 786px;
            top: 115px;
            height: 80px;
            background-size: 586%;
          }

          &:nth-child(16) {
            width: 105px;
            background-position: left -367px center;
            left: 367px;
            background-size: 1050%;
          }
        }
        @media (min-width: 1560px) {
          height: 80%;

          &:nth-child(1) {
            width: 128px;
            background-position: left -54px center;
            left: 55px;
            background-size: 1045%;
          }

          &:nth-child(2) {
            width: 113px;
            background-position: left -188px center;
            left: 188px;
            background-size: 1190%;
          }

          &:nth-child(3) {
            width: 122px;
            background-position: left -447px top;
            left: 447px;
            top: 102px;
            height: 128px;
          }

          &:nth-child(4) {
            width: 122px;
            background-position: left -446px bottom -14px;
            left: 447px;
            top: 260px;
            height: 179px;
          }

          &:nth-child(5) {
            width: 120px;
            background-position: left -567px center;
            left: 570px;
            background-size: 1115%;
          }

          &:nth-child(6) {
            width: 122px;
            background-position: left -831px top -10px;
            left: 832px;
            top: 120px;
            height: 145px;
          }

          &:nth-child(7) {
            width: 123px;
            background-position: left -838px bottom -15px;
            left: 832px;
            top: 271px;
            height: 158px;
          }

          &:nth-child(8) {
            width: 110px;
            background-position: left -979px top -14px;
            left: 952px;
            top: 106px;
            height: 116px;
          }

          &:nth-child(9) {
            width: 110px;
            background-position: left -976px bottom;
            left: 953px;
            top: 294px;
            height: 142px;
          }

          &:nth-child(10) {
            width: 113px;
            background-position: left -1078px top;
            left: 1068px;
            top: 99px;
            height: 126px;
          }

          &:nth-child(11) {
            width: 113px;
            background-position: left -1078px bottom;
            left: 1068px;
            top: 293px;
            height: 143px;
            background-size: 1200%;
          }

          &:nth-child(12) {
            width: 111px;
            background-position: left -1174px top;
            left: 1185px;
            top: 117px;
            height: 158px;
          }

          &:nth-child(13) {
            width: 121px;
            background-position: left -1173px bottom 2px;
            left: 1185px;
            top: 282px;
            height: 165px;
          }

          &:nth-child(14) {
            width: 120px;
            background-position: left -816px center;
            left: 833px;
          }

          &:nth-child(15) {
            width: 230px;
            background-position: left -959px top -11px;
            left: 956px;
            top: 95px;
            height: 94px;
          }

          &:nth-child(16) {
            width: 128px;
            background-position: left -443px center;
            left: 443px;
          }
        }
      }
    }
  }

  &[data-floor="0"] {
    .floor-projection__item-image {
      background-image: url("../images/flrs/0-pietro-white.png");

      .floor-projection__item-image-flat {
        &[data-status="available"] {
          background-image: url("../images/flrs/0-pietro-green.png");
        }

        &[data-status="reservation"],
        &[data-status="preReservation"] {
          background-image: url("../images/flrs/0-pietro-grey.png");
        }

        &[data-status="sold"] {
          background-image: url("../images/flrs/0-pietro-white.png");
        }

        &:nth-child(11) {
          display: none;
        }
        &:nth-child(12) {
          display: none;
        }
        &:nth-child(13) {
          display: none;
        }

        &:nth-child(1) {
          width: 83px;
          background-position: left -5px bottom -8px;
          left: 6px;
          height: 138px;
          bottom: 19px;
          top: auto;
          background-size: 1100%;
        }

        &:nth-child(2) {
          width: 78px;
          background-position: left -95px bottom -9px;
          left: 93px;
          height: 138px;
          bottom: 19px;
          top: auto;
          background-size: 1200%;
        }

        &:nth-child(3) {
          width: 110px;
          background-position: left -179px top -44px;
          left: 176px;
          height: 170px;
          bottom: 23px;
          top: auto;
          background-size: 850%;
        }

        &:nth-child(4) {
          width: 85px;
          background-position: left -294px bottom -10px;
          left: 290px;
          height: 138px;
          bottom: 19px;
          top: auto;
        }

        &:nth-child(5) {
          width: 80px;
          background-position: left -376px bottom -9px;
          left: 378px;
          height: 138px;
          bottom: 19px;
          top: auto;
          background-size: 1150%;
        }

        &:nth-child(6) {
          width: 110px;
          background-position: left -466px top -44px;
          left: 460px;
          height: 170px;
          bottom: 23px;
          top: auto;
          background-size: 850%;
        }

        &:nth-child(7) {
          width: 83px;
          background-position: left -568px bottom -7px;
          left: 575px;
          top: 208px;
          height: 210px;
        }

        &:nth-child(8) {
          width: 80px;
          background-position: left -690px top -27px;
          left: 662px;
          top: 208px;
          height: 210px;
        }

        &:nth-child(9) {
          width: 135px;
          background-position: left -763px bottom -10px;
          left: 744px;
          top: 260px;
          height: 105px;
          background-size: 700%;
        }

        &:nth-child(10) {
          width: 87px;
          background-position: left -864px top -41px;
          left: 833px;
          top: 161px;
          height: 85px;
          background-size: 1100%;
        }

        @media (min-width: 1400px) {
          height: 66%;

          &:nth-child(1) {
            width: 101px;
            background-size: 1100%;
            height: 170px;
            bottom: -20px;
            background-position: left -5px bottom -10px;
          }

          &:nth-child(2) {
            width: 95px;
            background-position: left -108px bottom -8px;
            left: 110px;
            height: 170px;
            bottom: -20px;
            background-size: 1150%;
          }

          &:nth-child(3) {
            width: 133px;
            background-position: left -216px top -56px;
            left: 209px;
            top: 210px;
            height: 202px;
          }

          &:nth-child(4) {
            width: 101px;
            background-position: left -349px bottom -10px;
            left: 347px;
            height: 170px;
            bottom: -20px;
          }

          &:nth-child(5) {
            width: 95px;
            background-position: left -446px bottom -8px;
            left: 451px;
            height: 170px;
            bottom: -20px;
          }

          &:nth-child(6) {
            width: 133px;
            background-position: left -560px top -55px;
            left: 548px;
            top: 210px;
            height: 202px;
          }

          &:nth-child(7) {
            width: 101px;
            background-position: left -691px bottom -9px;
            left: 686px;
            top: 210px;
            height: 252px;
          }

          &:nth-child(8) {
            width: 95px;
            background-position: left -784px top -25px;
            left: 791px;
            top: 210px;
            height: 252px;
            background-size: 1150%;
          }

          &:nth-child(9) {
            width: 162px;
            background-position: left -916px bottom -11px;
            left: 890px;
            top: 270px;
            height: 130px;
          }

          &:nth-child(10) {
            width: 105px;
            background-position: left -1042px top -56px;
            left: 994px;
            top: 156px;
            height: 100px;
            background-size: 1100%;
          }
        }
        @media (min-width: 1560px) {
          height: 80%;

          &:nth-child(1) {
            width: 128px;
            background-position: left 0px bottom -12px;
            left: 2px;
            background-size: 1045%;
            height: 205px;
            bottom: -67px;
          }

          &:nth-child(2) {
            width: 114px;
            background-position: left -138px bottom -13px;
            left: 136px;
            background-size: 1190%;
            height: 205px;
            bottom: -67px;
          }

          &:nth-child(3) {
            width: 162px;
            background-position: left -263px top -68px;
            left: 255px;
            top: 212px;
            height: 246px;
          }

          &:nth-child(4) {
            width: 124px;
            background-position: left -431px bottom -13px;
            left: 422px;
            height: 205px;
            bottom: -67px;
            top: auto;
          }

          &:nth-child(5) {
            width: 114px;
            background-position: left -561px bottom -14px;
            left: 549px;
            background-size: 1200%;
            height: 205px;
            bottom: -67px;
            top: auto;
          }

          &:nth-child(6) {
            width: 162px;
            background-position: left -686px top -68px;
            left: 669px;
            top: 212px;
            height: 246px;
          }

          &:nth-child(7) {
            width: 121px;
            background-position: left -829px bottom -9px;
            left: 837px;
            top: 211px;
            height: 307px;
          }

          &:nth-child(8) {
            width: 116px;
            background-position: left -956px top -31px;
            left: 963px;
            top: 211px;
            height: 307px;
          }

          &:nth-child(9) {
            width: 196px;
            background-position: left -1108px bottom -12px;
            left: 1083px;
            top: 288px;
            height: 154px;
          }

          &:nth-child(10) {
            width: 127px;
            background-position: left -1263px top -67px;
            left: 1210px;
            top: 146px;
            height: 120px;
          }
        }
      }
    }
  }

  &[data-floor="1"] {
    .floor-projection__item-image {
      background-image: url("../images/flrs/1-pietro-white.png");

      .floor-projection__item-image-flat {
        &[data-status="available"] {
          background-image: url("../images/flrs/1-pietro-green.png");
        }

        &[data-status="reservation"],
        &[data-status="preReservation"] {
          background-image: url("../images/flrs/1-pietro-grey.png");
        }

        &[data-status="sold"] {
          background-image: url("../images/flrs/1-pietro-white.png");
        }
      }
    }
  }
  &[data-floor="2"] {
    .floor-projection__item-image {
      background-image: url("../images/flrs/2-pietro-white.png");

      .floor-projection__item-image-flat {
        &[data-status="available"] {
          background-image: url("../images/flrs/2-pietro-green.png");
        }

        &[data-status="reservation"],
        &[data-status="preReservation"] {
          background-image: url("../images/flrs/2-pietro-grey.png");
        }

        &[data-status="sold"] {
          background-image: url("../images/flrs/2-pietro-white.png");
        }
      }
    }
  }
  &[data-floor="3"] {
    .floor-projection__item-image {
      background-image: url("../images/flrs/3-pietro-white.png");

      .floor-projection__item-image-flat {
        &[data-status="available"] {
          background-image: url("../images/flrs/3-pietro-green.png");
        }

        &[data-status="reservation"],
        &[data-status="preReservation"] {
          background-image: url("../images/flrs/3-pietro-grey.png");
        }

        &[data-status="sold"] {
          background-image: url("../images/flrs/3-pietro-white.png");
        }
      }
    }
  }
  &[data-floor="4"] {
    .floor-projection__item-image {
      background-image: url("../images/flrs/4-pietro-white.png");

      .floor-projection__item-image-flat {
        &[data-status="available"] {
          background-image: url("../images/flrs/4-pietro-green.png");
        }

        &[data-status="reservation"],
        &[data-status="preReservation"] {
          background-image: url("../images/flrs/4-pietro-grey.png");
        }

        &[data-status="sold"] {
          background-image: url("../images/flrs/4-pietro-white.png");
        }

        &:nth-child(3) {
          display: none;
        }
        &:nth-child(4) {
          display: none;
        }
        &:nth-child(16) {
          display: block;
        }

        &:nth-child(6) {
          background-position: left -568px top -7px;
          height: 90px;
        }

        &:nth-child(7) {
          background-position: left -568px bottom -13px;
          top: 243px;
        }

        &:nth-child(9) {
          background-position: left -663px bottom -5px;
          top: 258px;
        }

        &:nth-child(12) {
          background-position: left -813px top -8px;
          top: 143px;
          height: 93px;
        }

        &:nth-child(13) {
          background-position: left -813px bottom -4px;
          top: 250px;
        }

        @media (min-width: 1400px) {
          height: 66%;

          &:nth-child(6) {
            background-position: left -673px top -5px;
            height: 112px;
          }

          &:nth-child(7) {
            background-position: left -689px bottom -22px;
            top: 248px;
          }

          &:nth-child(9) {
            background-position: left -782px bottom -23px;
            top: 261px;
          }

          &:nth-child(10) {
            width: 99px;
            background-position: left -877px top -4px;
            left: 873px;
            height: 94px;
            background-size: 1120%;
          }

          &:nth-child(11) {
            background-position: left -887px bottom -26px;
            top: 260px;
          }

          &:nth-child(12) {
            background-position: left -973px top -16px;
            top: 136px;
            height: 109px;
          }

          &:nth-child(13) {
            background-position: left -969px bottom -6px;
            top: 259px;
          }
        }
        @media (min-width: 1560px) {
          height: 80%;

          &:nth-child(6) {
            width: 122px;
            background-position: left -831px top -10px;
            left: 832px;
            top: 114px;
            height: 135px;
          }

          &:nth-child(7) {
            width: 123px;
            background-position: left -838px bottom -24px;
            left: 832px;
            top: 261px;
            height: 158px;
          }

          &:nth-child(9) {
            width: 110px;
            background-position: left -979px bottom -10px;
            left: 955px;
            top: 285px;
            height: 142px;
          }

          &:nth-child(10) {
            width: 120px;
            background-position: left -1063px top 1px;
            left: 1063px;
            top: 99px;
            height: 126px;
          }

          &:nth-child(11) {
            width: 113px;
            background-position: left -1078px bottom -8px;
            left: 1068px;
            top: 285px;
            height: 143px;
          }

          &:nth-child(12) {
            width: 111px;
            background-position: left -1174px top -8px;
            left: 1185px;
            top: 117px;
            height: 143px;
          }

          &:nth-child(13) {
            width: 121px;
            background-position: left -1173px bottom -11px;
            left: 1185px;
            top: 270px;
            height: 165px;
          }
        }
      }
    }
  }
  &[data-floor="5"] {
    .floor-projection__item-image {
      background-image: url("../images/flrs/5-pietro-white.png");

      .floor-projection__item-image-flat {
        &[data-status="available"] {
          background-image: url("../images/flrs/5-pietro-green.png");
        }

        &[data-status="reservation"],
        &[data-status="preReservation"] {
          background-image: url("../images/flrs/5-pietro-grey.png");
        }

        &[data-status="sold"] {
          background-image: url("../images/flrs/5-pietro-white.png");
        }

        &:nth-child(3) {
          display: none;
        }
        &:nth-child(4) {
          display: none;
        }
        &:nth-child(6) {
          display: none;
        }
        &:nth-child(7) {
          display: none;
        }
        &:nth-child(8) {
          display: none;
        }

        &:nth-child(10) {
          display: none;
        }

        &:nth-child(14) {
          display: block;
        }
        &:nth-child(15) {
          display: block;
        }
        &:nth-child(16) {
          display: block;
        }

        &:nth-child(9) {
          background-position: left -663px bottom -11px;
          top: 252px;
        }

        &:nth-child(11) {
          background-position: left -732px bottom -6px;
          top: 254px;
        }

        &:nth-child(12) {
          background-position: left -813px top -5px;
          height: 92px;
          top: 139px;
        }

        &:nth-child(13) {
          background-position: left -813px bottom -12px;
          top: 243px;
        }

        @media (min-width: 1400px) {
          height: 66%;

          &:nth-child(9) {
            background-position: left -782px bottom -31px;
            top: 253px;
          }

          &:nth-child(11) {
            background-position: left -887px bottom -34px;
            top: 252px;
          }

          &:nth-child(12) {
            background-position: left -973px top -24px;
            top: 136px;
          }

          &:nth-child(13) {
            background-position: left -969px bottom -15px;
            top: 251px;
          }
        }
        @media (min-width: 1560px) {
          height: 80%;

          &:nth-child(9) {
            width: 110px;
            background-position: left -979px bottom -19px;
            left: 955px;
            top: 275px;
            height: 142px;
          }

          &:nth-child(11) {
            width: 113px;
            background-position: left -1078px bottom -17px;
            left: 1068px;
            top: 276px;
            height: 143px;
          }

          &:nth-child(12) {
            width: 111px;
            background-position: left -1174px top -11px;
            left: 1185px;
            top: 112px;
            height: 127px;
          }

          &:nth-child(13) {
            width: 121px;
            background-position: left -1173px bottom -21px;
            left: 1185px;
            top: 261px;
            height: 165px;
          }
        }
      }
    }
  }

  .floor-projection__floor-choose {
    max-width: 290px;
    width: 290px;
    margin-top: 60px;

    .floor-projection__floor-title {
      width: 100%;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      display: block;
      margin-bottom: 18px;
    }

    .floor-projection__floor {
      width: 100%;
      display: block;
      border: 3px solid #000;
      padding: 25px;
      text-align: center;
      position: relative;
      background-color: #fff;
      cursor: pointer;
      min-height: 80px;
      max-height: 80px;
      height: 80px;

      &:hover {
        background-color: var(--s7-color-green);
      }

      span {
        font-size: 20px;
        font-family: "Limelight";
        text-align: center;
      }
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        background-color: #fff;
        position: absolute;
        bottom: 78px;
        width: 100%;
        left: 0;
        right: 0;
        padding: 10px;
        box-shadow: 0px 3px 6px #00000029;
        opacity: 0;
        pointer-events: none;
        transition: 0.3s;
        li {
          font-size: 20px;
          text-align: center;
          margin-bottom: 10px;
          cursor: pointer;

          &:hover {
            text-shadow: 0 1px 1.5px rgba($color: #000000, $alpha: 1);
          }
          &.active {
            text-shadow: 0 1px 1.5px rgba($color: #000000, $alpha: 1);
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      &:focus {
        ul {
          opacity: 1;
          pointer-events: all;
        }
      }
    }
  }
}

.floor-projection__item-image-flat-info {
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  padding: 15px;
  position: fixed;
  min-width: 205px;
  text-align: left;
  opacity: 0;
  pointer-events: none;
  z-index: 10;

  &.active {
    opacity: 1;
  }
  p {
    margin-bottom: 3px;
    font-size: 14px;
    b {
      margin-left: 5px;
    }

    &:first-child {
      b {
        font-size: 14px;

        span:first-child {
          margin-right: 10px;
          font-size: 20px;
          font-weight: normal;
        }
      }
    }
  }

  .promo {
    display: none;

    &.show {
      display: block;
      color: red;
    }
  }
}
