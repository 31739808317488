@import "slider";

h2 {
  text-transform: uppercase;
  text-align: center;
  font-size: 15px;
  font-family: "Limelight";
  margin-bottom: 11px;
}

.app__text-box {
  position: relative;
  padding-top: 19.5px;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;

  .app__text {
    font-size: 12px;
    text-align: center;
    line-height: 1.4;
  }
}

.line {
  height: 3px;
  width: 0;
  background-color: #000;
  left: 0;
  top: 0;
  position: absolute;
  transition: 0.5s ease-in-out;
}

section {
  &.anim {
    .line {
      width: 100%;
    }
  }
  &.anim-text {
    .app__text-box {
      opacity: 1;
      pointer-events: all;
    }
  }
}

.image-bg {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 1200px) {
  .col--small {
    height: 248px;

    h2 {
      padding-top: 50px;
    }
  }

  .col--big {
    height: 344px;
    display: flex;
    justify-content: flex-end;

    h2 {
      padding-top: 102px;
    }
  }

  h2 {
    font-size: 35px;
    text-align: right;
    margin-right: 95px;
    margin-bottom: 0px;
  }

  .app__text-box {
    padding-top: 0;
    height: 100%;
    display: flex;
    align-items: center;

    .app__text {
      font-size: 18px;
      text-align: left;
      padding-left: 96px;
      max-width: 874px;
      line-height: 24px;
      padding-right: 65px;
    }
  }

  .line {
    height: 0;
    width: 10px;
  }

  .image-bg {
    max-width: 100%;
  }

  section {
    padding-left: 192px;
    &.anim {
      .line {
        width: 10px;
        height: 100%;
      }
    }
  }
}

@media (min-width: 1650px) {
  .app__text-box {
    .app__text {
      padding-right: 40px;
    }
  }
}

@media (min-width: 1750px) {
  .app__text-box {
    .app__text {
      padding-right: 0px;
    }
  }
}

@import "ZoomedImage";

@import "Header";

@import "Popup";

@import "Location";

@import "Map";

@import "No";

@import "Video";

@import "Tiles";

@import "Investment";

@import "Sketch";

@import "Rectangle";

@import "Standard";

@import "ImageSlider";

@import "ImagesRow";

@import "Prestige";

@import "Plus";

@import "Green";

@import "GreenMap";

@import "Investor";

@import "Flat";

@import "Floors";

@import "Form";

@import "Policy";

@import "Offer";

@import "FloorProjection";

@import "OfferFilter";

@import "OfferResult";

@import "OfferDetails";

@import "Footer";

// #line-svg {
//    position: absolute;
//    left: 0;
//    top: 0;
// }
