.form {
   padding-top: 50px;
   padding-bottom: 35px;
   opacity: 0;
   transition: 1s ease-in-out;

   &.anim-text {
      opacity: 1;
   }

   form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 38px;

      .form__input-box {
         font-size: 12px;
         font-weight: bold;
         margin-bottom: 25px;
         position: relative;

         input[type="text"],
         input[type="number"] {
            font-size: 12px;
            font-weight: bold;
            border: none;
            border-bottom: 3px solid #000;
            text-align: center;
            padding: 8px;
            outline: none;
            width: 100%;
            font-family: "Montserrat";
         }

         input[type="number"]::-webkit-outer-spin-button,
         input[type="number"]::-webkit-inner-spin-button {
            -webkit-appearance: none;
         }

         input[type="number"] {
            -moz-appearance: textfield;
         }

         &:before {
            position: absolute;
            left: 0;
            width: 100%;
            bottom: -15px;
            color: rgba(255, 0, 0, 0.75);
            transition: 0.3s;
            font-size: 9px;
            font-weight: bold;
            font-family: "Montserrat";

            opacity: 0;
            pointer-events: none;
         }

         &.err:before {
            opacity: 1;
         }

         &:after {
            position: absolute;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: inherit;
            width: 100%;
            top: 50%;
            color: inherit;
            text-align: center;
            pointer-events: none;
            transition: 0.3s;
            // font-size: 12px;
            font-weight: bold;
            font-family: "Montserrat";
         }

         &[data-name="name"]:after {
            content: "imię i nazwisko";
         }

         &[data-name="name"]:before {
            content: "Imię jest za krótkie";
         }

         &[data-name="email"]:after {
            content: "e-mail";
         }

         &[data-name="email"]:before {
            content: "Nieprawidłowy adres email";
         }

         &[data-name="phone"]:after {
            content: "telefon";
         }

         &[data-name="phone"]:before {
            content: "Nieprawidłowy numer telefonu";
         }

         &[data-name="question"]:after {
            content: "o jakie mieszkanie pytasz?";
         }

         &[data-name="question"]:before {
            content: "Wypełnij to pole";
         }

         &[data-name="question"] {
            margin-bottom: 45px;
         }

         &.focus:after {
            opacity: 0;
         }
      }

      &:focus {
         background-color: #000;
      }

      .form__check {
         // display: flex;
         // justify-content: space-between;
         // text-align: center;

         .form__check-item {
            // width: 45%;
            display: flex;
            margin-bottom: 10px;

            input {
               width: 20px;
               min-width: 20px;
               max-width: 20px;
               height: 20px;
               min-height: 20px;
               max-height: 20px;
               border-radius: 0;
               margin: 0 15px 6px 0;
               position: relative;
               appearance: none;
               display: block;

               &:before {
                  content: "";
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  left: 0;
                  top: 0;
                  border: 2px solid var(--s7-color-green);
               }

               &:after {
                  content: "\2713";
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                  font-size: 15px;
                  color: #fff;
               }

               &:checked {
                  background-color: var(--s7-color-green);
               }
            }

            .form__check-item-text {
               font-size: 9px;
               font-weight: 300;
               color: #000;
               line-height: 11px;
               display: block;
            }
         }
      }

      .form__button {
         margin-top: 30px;

         button {
            width: 100%;
            height: 32px;
            border: 0;
            outline: 0;
            background-color: var(--s7-color-green);
            color: #fff;
            font-size: 12px;
            font-family: "Limelight";
            transition: 0.3s;

            &:hover {
               opacity: 0.9;
            }
         }
      }

      .form__check-info {
         font-size: 10px;
         font-weight: 300;

         &.err {
            color: rgba(255, 0, 0, 0.75);
            font-weight: bold;
         }
      }
   }

   .form__call-us {
      text-align: center;
      .form__call-us-text-primary {
         font-size: 12px;
         font-weight: bold;
         margin-bottom: 20px;
      }

      .form__call-us-text-secondary {
         font-size: 12px;
         margin-bottom: 16px;
      }
   }
}

@media (min-width: 768px) {
   .form {
      form {
         .form__check {
            .form__check-item {
               margin-bottom: 15px;
               .form__check-item-text {
                  font-size: 12px;
                  line-height: 1.4;
               }
            }
         }

         .form__check-info {
            font-size: 12px;
            font-weight: 300;
         }
      }
   }
}

@media (min-width: 1200px) {
   .form {
      padding-top: 80px;
      padding-bottom: 85px;

      form {
         max-width: 1152px;
         margin-left: auto;
         margin-right: auto;
         margin-bottom: 0px;

         .form__input-box {
            font-size: 18px;
            margin-bottom: 25px;

            input[type="text"],
            input[type="number"] {
               font-size: 18px;
               border-bottom: 5px solid #000;
               text-align: left;
               padding: 12px 96px;
            }

            &:after {
               left: 0;
               padding-left: 96px;
               transform: translate(0, -60%);
               width: 100%;
               text-align: left;
            }

            &:before {
               right: 20px;
               left: auto;
               bottom: auto;
               top: 50%;
               width: auto;
               transform: translateY(-50%);
               font-size: 12px;
            }

            &[data-name="question"] {
               margin-bottom: 51px;
            }
         }

         .form__check {
            .form__check-item {
               margin-bottom: 20px;
               input {
                  margin: 0 20px 7px 0;
               }

               .form__check-item-text {
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 22px;
               }
            }
         }

         .form__button {
            margin-top: 60px;
            margin-left: auto;
            margin-right: auto;

            button {
               width: 768px;
               height: 86px;
               font-size: 25px;
            }
         }

         .form__check-info {
            font-size: 14px;
         }
      }
   }
}

.form--offer {
   // opacity: 1;
   pointer-events: none;
   position: fixed;
   left: 0;
   right: 0;
   top: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(112, 112, 112, 0.8);
   z-index: 999999;
   padding: 0;

   &.active {
      opacity: 1;
      pointer-events: all;
   }

   .form-box {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      width: 95%;
      padding: 35px 35px 30px;
      max-width: 400px;
      max-height: 85%;
      overflow: auto;

      @media (min-width: 1200px) {
         padding: 50px 95px;
         max-width: 1350px;
      }

      .form-box__title {
         font-size: 12px;
         font-weight: bold;
         color: var(--s7-color-green);
         text-align: center;
         margin-bottom: 28px;

         span {
            display: inline-block;
            margin-left: 5px;
         }

         @media (min-width: 1200px) {
            font-size: 18px;
            margin-bottom: 22px;
         }
      }

      form {
         margin-bottom: 0;
         .form__input-box[data-name="question"] {
            &[data-name="question"]:after {
               content: "treść wiadomości";
            }

            @media (min-width: 1200px) {
               &:before {
                  bottom: -20px;
                  top: auto;
                  transform: translateY(0);
               }
            }
         }

         .form__input-box {
            textarea {
               width: 100%;
               height: 100px;
               border: none;
               border-bottom: 3px solid #000;
               font-family: "Montserrat";
               outline: none;
               font-weight: bold;
               font-size: 12px;

               @media (min-width: 1200px) {
                  border-bottom: 5px solid #000;
                  padding: 12px 96px;
                  font-size: 18px;
                  height: 110px;
               }
            }
         }
      }
   }
}
