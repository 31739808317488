footer {
   background-color: var(--s7-color-green);
   padding-top: 37px;
   padding-bottom: 15px;

   h3 {
      text-align: center;
      font-size: 12px;
      font-weight: bold;
      margin-bottom: 15px;
      width: 100%;
   }

   .footer__item {
      margin-bottom: 10px;

      .footer__item-text {
         font-size: 12px;
      }
   }

   .col--middle {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .footer__col-left {
         width: 140px;
      }

      .footer__col-right {
         width: 110px;
      }

      .footer__disclaimer {
         font-size: 12px;
         margin-top: 20px;
      }
   }

   .footer__item-fb {
      a {
         display: inline-block;
         transition: 0.3s;
         &:hover {
            transform: scale(0.8);
         }
      }
   }

   .footer__item-legend {
      .footer__item-text-legend {
         font-size: 11px;
         font-weight: 200;
         color: #fff;
         opacity: 0.5;
      }
   }

   .footer__politics {
      text-align: center;
      margin-top: 15px;

      a {
         color: #000;
         font-size: 12px;
      }
   }
}

@media (min-width: 1200px) {
   footer {
      background-color: var(--s7-color-green);
      padding-top: 0;
      padding-bottom: 0;
      height: 300px;
      border-top: 12px solid #000;
      border-bottom: 12px solid #000;

      .container {
         height: 100%;
         padding: 0;
         margin: 0;
         max-width: 100%;
      }

      h3 {
         font-size: 18px;
         margin-bottom: 35px;
         margin-top: 32px;
         text-align: left;
      }

      .col--middle {
         border-left: 9px solid #000;
         border-right: 9px solid #000;
         padding-left: 182px !important;
         position: relative;
         justify-content: flex-start;
         align-items: flex-start;
         align-content: flex-start;
         align-items: flex-start;

         .footer__col-left {
            width: 225px;
         }

         .footer__col-right {
            width: auto;
         }

         .footer__disclaimer {
            font-size: 12px;
            margin-top: 5px;
            margin-left: -140px;
            padding-right: 220px;
            max-width: 550px;
         }
      }

      .col--first {
         padding-right: 100px !important;
         align-items: flex-end;
         flex-direction: column;
      }

      .col--last {
         padding-left: 86px !important;
         background-position: center center;
         background-size: cover;

         h3 {
            margin-bottom: 55px;
         }

         .footer__logo {
            width: 185px;
            height: 45px;
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat;
         }
      }

      .footer__item {
         margin-bottom: 5px;

         .footer__item-text {
            font-size: 18px;
         }
      }

      .footer__item-legend {
         position: absolute;
         top: 175px;
         left: 405px;
         max-width: 192px;
         .footer__item-text-legend {
            font-size: 16px;
            color: #000;
            opacity: 1;
         }
      }

      .footer__politics {
         text-align: left;
         margin-top: 30px;

         a {
            font-size: 18px;
         }
      }
   }
}

@media (min-width: 1630px) {
   footer {
      .col--middle {
         .footer__col-left {
            width: 300px;
         }

         .footer__disclaimer {
            font-size: 14px;
            margin-top: 10px;
            max-width: 600px;
            padding-right: 40px;
         }
      }

      .footer__item-legend {
         top: 118px;
         left: 620px;
      }
   }
}

.footer-offer {
   padding: 25px 35px;
   .footer-offer__text {
      font-size: 10px;
      text-align: center;
      line-height: 22px;
   }

   .footer-offer__fb {
      max-width: 190px;
      a {
         font-size: 18px;
         font-weight: bold;
         color: #000;
         text-decoration: none;
         display: inline-block;
         transition: 0.3s;

         .footer-offer__fb-img {
            width: 50px;
            height: 50px;
            margin-top: 5px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            background-image: url("../images/icons/fb.svg");
         }

         &:hover {
            .footer-offer__fb-img {
               background-image: url("../images/icons/fb-white.svg");
            }
         }
      }
   }

   @media (min-width: 576px) {
      padding: 35px 25px;
      .footer-offer__text {
         font-size: 12px;
      }
   }

   @media (min-width: 1200px) {
      // height: 140px;
      // padding: 0 95px;
      height: auto;
      padding: 40px 95px;
      border-bottom: 0;

      .footer-offer__text {
         font-size: 12px;
         text-align: left;
         max-width: 768px;
      }
   }
}
