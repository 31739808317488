.green {
   padding-top: 50px;
   padding-bottom: 35px;

   .slider {
      .slider__container--green[data-index="0"] {
         height: 135px;
      }

      .slider__container--green[data-index="1"] {
         height: 100px;
      }
   }
}

@media (min-width: 550px) {
   .green {
      .slider {
         .slider__container--green[data-index="0"] {
            height: 85px;
         }

         .slider__container--green[data-index="1"] {
            height: 70px;
         }
      }
   }
}

@media (min-width: 1200px) {
   .green {
      padding-top: 75px;
      padding-bottom: 75px;

      h2 {
         padding-top: 90px;
      }

      .slider {
         .slider__container--green[data-index="0"] {
            height: 135px;
         }

         .slider__container--green[data-index="1"] {
            height: 100px;
         }
      }
   }
}

@media (min-width: 1400px) {
   .green {
      h2 {
         padding-top: 100px;
      }

      .slider {
         .slider__container--green[data-index="0"] {
            height: 85px;
         }

         .slider__container--green[data-index="1"] {
            height: 70px;
         }
      }
   }
}
