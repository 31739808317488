.slider {
   overflow: hidden;
   position: relative;

   .slider-controls {
      margin: 20px 0 0;

      .slider-dots {
         display: flex;
         justify-content: center;
         pointer-events: none;

         .slider-dots__dot {
            margin: 0 8px;
            width: 10px;
            height: 11px;
            border-radius: 50%;
            background-color: #000;
            font-size: 0;
            cursor: pointer;
            transition: 0.3s ease;
            display: block;
            opacity: 0.5;
            pointer-events: all;

            &.slider-dots__dot--active {
               opacity: 1;
            }
         }
      }
   }

   .slider__container {
      min-width: 400%;
      display: flex;
      position: relative;

      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      /* pointer-events: none; */

      &.in-transit {
         -webkit-transition: 0.3s ease;
         -o-transition: 0.3s ease;
         transition: 0.3s ease;
      }

      &[data-index="0"] {
         left: 0;
      }

      &[data-index="1"] {
         left: -100%;
      }

      &[data-index="2"] {
         left: -200%;
      }

      &[data-index="3"] {
         left: -300%;
      }

      &[data-index="4"] {
         left: -400%;
      }

      &[data-index="5"] {
         left: -500%;
      }

      &[data-index="6"] {
         left: -600%;
      }

      &[data-index="7"] {
         left: -700%;
      }

      &[data-index="8"] {
         left: -800%;
      }

      .slider__container-item {
         max-width: 25%;
         min-width: 25%;
      }
   }
}

.slider-arrows {
   width: 110%;
   max-width: 110%;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   display: flex;
   justify-content: space-between;
   position: absolute;
   pointer-events: none;
   padding: 0;

   .slider-arrows__arrow {
      width: 10.5px;
      height: 18px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      cursor: pointer;
      z-index: 2;
      pointer-events: all;

      &.arrow--prev {
         background-image: url("../images/icons/ico-larrow-grey.svg");
         left: 0;
      }

      &.arrow--next {
         background-image: url("../images/icons/ico-rarrow-grey.svg");
         right: 0;
      }
   }
}

// @media (min-width: 768px) {
//    .slider {
//       .slider__container {
//          min-width: 100%;

//          .slider__container-item {
//             max-width: 50%;
//          }
//       }
//    }
//    .slider-arrows {
//       width: 100%;
//    }
// }

@media (min-width: 768px) {
   .slider-arrows {
      width: 80%;
   }
}

@media (min-width: 1200px) {
   .slider {
      align-self: center;

      // .slider__container {
      //    min-width: 100%;

      //    .slider__container-item {
      //       max-width: 33.333%;
      //    }
      // }

      .slider-controls {
         .slider-dots {
            .slider-dots__dot {
               margin: 0 5px;
               width: 14px;
               height: 15px;
            }
         }
      }
   }

   .slider-arrows {
      width: 59%;
      max-width: 100%;
      left: auto;
      right: 25px;
      top: 45%;
      transform: translate(0%, -50%);
   }
}

@media (min-width: 1400px) {
   .slider-arrows {
      right: 45px;
      width: 59%;
   }
}

@media (min-width: 1650px) {
   .slider-arrows {
      right: 105px;
      width: 57%;
   }
}
