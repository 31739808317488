.plus {
   .plus__image {
      position: relative;
      height: 225px;
      width: 100%;
      overflow: hidden;

      .plus__image-item {
         position: absolute;
         cursor: pointer;
         background-color: #d5d6c5;
         box-shadow: 0px 2px 6px #000000b3;
         border-radius: 50%;
         opacity: 0;

         span {
            font-size: 22px;
            width: 25px;
            height: 25px;
            line-height: 25px;
            display: inline-block;
            text-align: center;
            font-weight: 300;
            transition: 0.3s;
         }

         .plus__image-item-text {
            opacity: 0;
            transition: 0.3s;
            pointer-events: none;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            font-size: 12px;
            font-family: "Limelight";
            color: #fff;
            text-shadow: 0px 3px 6px #000000e8;
            text-align: center;
            width: 240px;
            display: inline-block;
         }

         &:hover {
            span {
               transform: scale(0.8);
            }
            .plus__image-item-text {
               opacity: 1;
               pointer-events: all;
            }
         }
      }

      .plus__image-item:nth-of-type(1) {
         transition: 0.3s 0.5s;
         bottom: 25px;
         left: 25px;

         .plus__image-item-text {
            top: -50px;
            width: 100px;
            left: 150%;
         }
      }

      .plus__image-item:nth-of-type(2) {
         transition: 0.3s;
         top: 30px;
         left: 155px;

         .plus__image-item-text {
            bottom: -25px;
         }
      }

      .plus__image-item:nth-of-type(3) {
         transition: 0.3s 1s;
         bottom: 10px;
         left: 145px;

         .plus__image-item-text {
            top: -25px;
            width: 280px;
         }
      }

      .plus__image-item:nth-of-type(4) {
         transition: 0.3s 1.5s;
         bottom: 60px;
         left: 220px;

         .plus__image-item-text {
            top: -40px;
            width: 130px;
         }
      }

      .plus__image-item:nth-of-type(5) {
         transition: 0.3s 2s;
         bottom: 30px;
         left: 300px;

         .plus__image-item-text {
            bottom: 30px;
            width: 130px;
            left: 35%;
         }
      }
   }

   &.anim-text {
      .plus__image-item {
         opacity: 1;
      }
   }
}

@media (max-width: 1199px) {
   .plus {
      .container {
         max-width: 100%;
         margin: 0;
         padding: 0;
      }
   }
}

@media (min-width: 768px) {
   .plus {
      .plus__image {
         height: 500px;
         max-width: 768px;

         .plus__image-item {
            span {
               font-size: 40px;
               width: 45px;
               height: 45px;
               line-height: 45px;
            }

            .plus__image-item-text {
               font-size: 20px;
               width: 240px;
            }
         }

         .plus__image-item:nth-of-type(1) {
            bottom: 60px;
            left: 35px;

            .plus__image-item-text {
               top: -80px;
               width: 200px;
            }
         }

         .plus__image-item:nth-of-type(2) {
            top: 70px;
            left: 330px;

            .plus__image-item-text {
               bottom: -35px;
            }
         }

         .plus__image-item:nth-of-type(3) {
            bottom: 30px;
            left: 300px;

            .plus__image-item-text {
               top: -35px;
            }
         }

         .plus__image-item:nth-of-type(4) {
            bottom: 145px;
            left: 485px;

            .plus__image-item-text {
               top: -60px;
               width: 230px;
            }
         }

         .plus__image-item:nth-of-type(5) {
            bottom: 70px;
            left: 650px;

            .plus__image-item-text {
               bottom: 55px;
               width: 230px;
               left: 50%;
            }
         }
      }
   }
}

@media (min-width: 1200px) {
   .plus {
      .plus__image {
         height: 600px;
         max-width: 985px;

         .plus__image-item {
            display: block;

            span {
               font-size: 60px;
               width: 50px;
               height: 50px;
               line-height: 50px;
            }
         }

         .plus__image-item:nth-of-type(1) {
            bottom: 75px;
            left: 75px;

            .plus__image-item-text {
               top: -80px;
               left: 50%;
            }
         }

         .plus__image-item:nth-of-type(2) {
            top: 80px;
            left: 430px;

            .plus__image-item-text {
               bottom: -35px;
            }
         }

         .plus__image-item:nth-of-type(3) {
            bottom: 40px;
            left: 400px;

            .plus__image-item-text {
               top: -40px;
               width: 280px;
            }
         }

         .plus__image-item:nth-of-type(4) {
            bottom: 170px;
            left: 620px;

            .plus__image-item-text {
               top: -65px;
               width: 280px;
            }
         }

         .plus__image-item:nth-of-type(5) {
            bottom: 90px;
            left: 810px;

            .plus__image-item-text {
               bottom: -60px;
               width: 260px;
            }
         }
      }
   }
}

@media (min-width: 1400px) {
   .plus {
      .plus__image {
         height: 755px;
         max-width: 1180px;

         .plus__image-item {
            span {
               font-size: 100px;
               width: 80px;
               height: 80px;
               line-height: 80px;
            }

            .plus__image-item-text {
               font-size: 25px;
            }
         }

         .plus__image-item:nth-of-type(1) {
            bottom: 100px;
            left: 90px;

            .plus__image-item-text {
               top: -100px;
               width: 300px;
            }
         }

         .plus__image-item:nth-of-type(2) {
            top: 90px;
            left: 510px;

            .plus__image-item-text {
               bottom: -90px;
            }
         }

         .plus__image-item:nth-of-type(3) {
            bottom: 40px;
            left: 460px;

            .plus__image-item-text {
               top: -60px;
               width: 280px;
            }
         }

         .plus__image-item:nth-of-type(4) {
            bottom: 215px;
            left: 750px;

            .plus__image-item-text {
               top: -80px;
               width: 280px;
            }
         }

         .plus__image-item:nth-of-type(5) {
            bottom: 100px;
            left: 960px;

            .plus__image-item-text {
               bottom: -80px;
               width: 320px;
            }
         }
      }
   }
}

@media (min-width: 1780px) {
   .plus {
      .plus__image {
         height: 926px;
         max-width: 100%;

         .plus__image-item:nth-of-type(1) {
            bottom: 120px;
            left: 140px;

            .plus__image-item-text {
               bottom: -100px;
               top: auto;
            }
         }

         .plus__image-item:nth-of-type(2) {
            top: 130px;
            left: 680px;
         }

         .plus__image-item:nth-of-type(3) {
            bottom: 55px;
            left: 620px;
         }

         .plus__image-item:nth-of-type(4) {
            bottom: 280px;
            left: 950px;
         }

         .plus__image-item:nth-of-type(5) {
            bottom: 150px;
            left: 1250px;

            .plus__image-item-text {
               bottom: -90px;
            }
         }
      }
   }
}
