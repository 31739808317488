.offer-filter {
   padding-top: 25px;
   padding-bottom: 40px;

   @media (min-width: 576px) {
      padding-top: 35px;
      padding-bottom: 50px;
   }

   @media (min-width: 768px) {
      padding-top: 55px;
      padding-bottom: 70px;
   }

   @media (min-width: 1200px) {
      padding-top: 125px;
      padding-bottom: 80px;
   }

   .offer-filter__item {
      margin-bottom: 30px;
      // &.offer-filter__item--slider {
      //    min-width: 356px;
      //    max-width: 356px;
      // }
      @media (min-width: 768px) {
         margin-bottom: 0;
      }

      .offer-filter__item-title {
         font-size: 14px;
         font-weight: bold;
         text-align: center;
         margin-bottom: 13px;

         @media (min-width: 576px) {
            font-size: 15px;
            margin-bottom: 15px;
         }

         @media (min-width: 1200px) {
            font-size: 18px;
            margin-bottom: 25px;
         }
      }

      .offer-filter__item-check {
         max-width: 125px;
         margin-left: auto;
         margin-right: auto;
         @media (min-width: 576px) {
            max-width: 100%;
         }
         .offer-filter__item-check-item {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            @media (min-width: 576px) {
               margin-bottom: 15px;
            }

            @media (min-width: 1200px) {
               margin-bottom: 20px;
            }
            .offer-filter__item-check-item-checkbox {
               margin-right: 15px;

               width: 20px;
               min-width: 20px;
               max-width: 20px;
               height: 20px;
               min-height: 20px;
               max-height: 20px;
               border-radius: 0;
               position: relative;
               appearance: none;
               display: block;
               background-color: #fff;

               &:before {
                  content: "";
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  left: 0;
                  top: 0;
               }

               &:after {
                  content: "\2715";
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                  font-size: 15px;
                  color: #000;
                  display: none;
                  font-weight: bold;
               }

               &:checked:after {
                  display: block;
               }
            }

            .offer-filter__item-check-item-text {
               font-size: 12px;

               @media (min-width: 576px) {
                  font-size: 14px;
               }

               @media (min-width: 1200px) {
                  font-size: 16px;
               }
            }
            &:last-child {
               margin-bottom: 0;
            }
         }
      }

      .offer-filter__item-slider {
         margin-top: 20px;
         margin-bottom: 50px;
         z-index: 0;
         position: relative;

         @media (min-width: 576px) {
            margin-bottom: 60px;
         }

         @media (min-width: 1200px) {
            margin-bottom: 80px;
         }

         .offer-filter__item-slider-dot {
            background-color: #000;
            border-radius: 50%;
            display: block;
            width: 10px;
            height: 10px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            z-index: 3;

            width: 17px;
            height: 17px;

            &.offer-filter__item-slider-dot--left {
               left: 0;
            }

            &.offer-filter__item-slider-dot--right {
               left: calc(100% - 17px);
            }

            // @media (min-width: 576px) {
            //    width: 13px;
            //    height: 13px;

            //    &.offer-filter__item-slider-dot--right {
            //       left: calc(100% - 13px);
            //    }
            // }

            // @media (min-width: 1200px) {
            //    width: 17px;
            //    height: 17px;

            //    &.offer-filter__item-slider-dot--right {
            //       left: calc(100% - 17px);
            //    }
            // }
         }

         .offer-filter__item-slider-line {
            height: 0.5px;
            background-color: #000;
            position: relative;

            .offer-filter__item-slider-line-diff {
               height: 2px;
               position: absolute;
               left: 0;
               width: 100%;
               top: 50%;
               transform: translateY(-50%);
               background-color: #000;
               z-index: 2;
            }

            @media (min-width: 1200px) {
               height: 1px;
            }
         }

         .offer-filter__item-slider-value {
            position: absolute;
            bottom: -25px;
            font-size: 12px;

            @media (min-width: 1200px) {
               bottom: -35px;
               font-size: 16px;
            }

            &.offer-filter__item-slider-value--left {
               left: 0;
            }

            &.offer-filter__item-slider-value--right {
               right: 0;
            }
         }
      }
   }
}
