.sketch {
   padding-bottom: 40px;

   .sketch__box {
      margin-bottom: 30px;
      height: 255px;
   }

   .sketch__box-text {
      text-align: center;

      p {
         font-size: 12px;
         margin-bottom: 20px;
         font-style: italic;
      }
   }
}

@media (max-width: 1199px) {
   .sketch {
      & > .container {
         max-width: 100%;
         margin: 0;
         padding: 0;
      }
   }
}

@media (min-width: 768px) {
   .sketch {
      .sketch__box {
         height: 570px;
         max-width: 100%;
      }
   }
}

@media (min-width: 1200px) {
   .sketch {
      padding-top: 20px;
      .sketch__box {
         margin-bottom: 0px;
         height: 685px;
         background-position: bottom center;
         // border-top: 2px solid var(--s7-color-dark1);
         // border-bottom: 2px solid var(--s7-color-dark1);
      }

      .sketch__box-text {
         margin-top: 115px;

         p {
            font-size: 16px;
            text-align: left;
            margin-bottom: 40px;
            max-width: 576px;
         }
      }
   }
}

@media (min-width: 1400px) {
   .sketch {
      .sketch__box {
         height: 985px;
      }
   }
}
