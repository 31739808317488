.greenMap {
   .greenMap__image1 {
      height: 270px;
      width: 100%;
   }

   .greenMap__image2 {
      height: 245px;
      width: 100%;
      background-size: contain;
   }
}

@media (min-width: 768px) {
   .greenMap {
      .greenMap__image1 {
         height: 33vw;
         max-width: 100%;
      }

      .greenMap__image2 {
         height: 33vw;
         max-width: 100%;
      }
   }
}

@media (min-width: 1200px) {
   .greenMap {
      height: 450px;
      padding-left: 0;

      .greenMap__image1 {
         height: 100%;
         width: 45%;
      }

      .greenMap__image2 {
         height: 100%;
         width: 55%;
      }
   }
}

@media (min-width: 1400px) {
   .greenMap {
      height: 735px;
   }
}
