.offer-details {
  margin-top: 90px;
  position: relative;
  padding-left: 0 !important;

  @media (min-width: 1200px) {
    display: flex;
    margin-top: 0px;
  }

  .offer-details__back {
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;

    button {
      transition: 0.3s;
      font-size: 14px;
      color: #000;
      text-decoration: none;
      cursor: pointer;
      font-weight: 600;
      background-color: transparent;
      border: none;
      outline: none;

      b {
        font-weight: 800;
      }
    }

    &:hover {
      button {
        transform: scale(1.05);
      }
    }

    @media (min-width: 768px) {
      margin-bottom: 40px;
      a {
        font-size: 16px;
      }
    }

    @media (min-width: 1200px) {
      margin-bottom: 55px;
    }
  }

  .offer-details__item {
    padding: 65px 35px 25px;

    @media (min-width: 576px) {
      padding: 65px 35px 45px;
    }

    @media (min-width: 768px) {
      padding: 65px 15px 45px;
    }

    @media (min-width: 1200px) {
      width: 50%;
      padding: 35px 50px 0;

      &:nth-child(1) {
        border-right: 12px solid #000;
      }
    }

    .offer-details__item-data {
      margin-bottom: 70px;
      display: flex;
      justify-content: space-between;
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;

      @media (min-width: 576px) {
        max-width: 350px;
      }

      @media (min-width: 768px) {
        max-width: 450px;
      }

      .offer-details__item-data-title {
        margin-bottom: 5px;
        font-size: 30px;
        b {
          font-size: 14px;
          display: block;
        }

        @media (min-width: 576px) {
          b {
            font-size: 15px;
          }
        }

        @media (min-width: 768px) {
          font-size: 35px;
          b {
            font-size: 18px;
          }
        }
      }

      .offer-details__item-data-price {
        text-align: right;
        .offer-details__item-data-price-full {
          margin-bottom: 15px;
          font-size: 12px;

          b {
            font-size: 14px;
            display: block;
            margin-top: 5px;
          }

          @media (min-width: 576px) {
            font-size: 14px;

            b {
              font-size: 16px;
            }
          }

          @media (min-width: 768px) {
            font-size: 16px;

            b {
              font-size: 18px;
            }
          }
        }

        .offer-details__item-data-price-area {
          color: var(--s7-color-green);
          font-size: 10px;

          &.red {
            color: red;
          }

          b {
            font-size: 11px;
            display: block;
            margin-top: 5px;
          }

          @media (min-width: 576px) {
            font-size: 12px;

            b {
              font-size: 13px;
            }
          }

          @media (min-width: 768px) {
            font-size: 14px;

            b {
              font-size: 16px;
            }
          }
        }
      }
    }

    .offer-details__item-image {
      width: 100%;
      height: 320px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      margin-bottom: 64px;

      @media (min-width: 1200px) {
        margin-bottom: 0px;
        height: 665px;
      }
    }

    .offer-details__arrow {
      width: 28px;
      height: 28px;
      margin-left: auto;
      margin-right: auto;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      cursor: pointer;
    }

    .offer-details__item-summary {
      @media (min-width: 768px) {
        max-width: 760px;
        margin-right: auto;
        margin-left: auto;
        display: flex;
        flex-wrap: wrap;
      }

      @media (min-width: 1200px) {
        padding: 110px 0px 70px;
      }

      @media (min-width: 1400px) {
        padding: 110px 50px 70px;
      }

      .offer-details__item-summary-title {
        text-align: center;
        font-size: 30px;
        margin-bottom: 35px;

        @media (min-width: 768px) {
          text-align: left;
          font-size: 40px;
          margin-bottom: 65px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          position: relative;

          b {
            font-size: 25px;
            margin-left: 25px;
            min-width: 143px;
            &:nth-of-type(1) {
              flex-grow: 1;
            }
          }

          .promo {
            position: absolute;
            font-size: 1.25rem;
            right: 0;
            top: 50px;
            text-align: right;

            b {
              color: red;
              display: block;
            }
          }
        }
      }

      .offer-details__item-summary-info {
        text-align: left;
        max-width: 150px;
        margin-left: auto;
        margin-right: auto;
        p {
          font-size: 12px;
          margin-bottom: 25px;
          b {
            margin-left: 10px;
          }
        }

        @media (min-width: 576px) {
          max-width: 200px;
          p {
            font-size: 14px;
          }
        }

        @media (min-width: 768px) {
          max-width: 200px;
          min-width: 200px;
          margin-left: 0;
          p {
            font-size: 16px;
            margin-bottom: 20px;
          }
        }

        @media (min-width: 1200px) {
        }
      }

      .offer-details__item-summary-image {
        width: 100%;
        height: 111px;
        margin-bottom: 55px;

        @media (min-width: 576px) {
          height: 121px;
        }

        @media (min-width: 768px) {
          height: 150px;
          margin-bottom: 0px;
          width: 60%;
          flex-shrink: 1;
          flex-grow: 1;
        }
      }

      .offer-details__item-summary-files {
        @media (min-width: 768px) {
          margin-top: 130px;
          width: 100%;
        }

        .offer-details__item-summary-files-item {
          margin-bottom: 30px;
          a,
          .offer-details__item-summary-files-item-btn {
            cursor: pointer;
            color: #000;
            text-decoration: none;
            display: flex;
            align-items: center;
            max-width: 220px;
            margin-left: auto;
            margin-right: auto;

            .offer-details__item-summary-files-item-image {
              margin-right: 10px;
              height: 35px;
              width: 35px;
              background-position: center center;
              background-repeat: no-repeat;
              background-size: cover;
            }

            .offer-details__item-summary-files-item-text {
              font-size: 12px;
            }
          }

          &:nth-child(1) {
            a,
            .offer-details__item-summary-files-item-btn {
              .offer-details__item-summary-files-item-image {
                background-image: url("../images/icons/document.svg");
              }

              &:hover {
                color: #fff;
                .offer-details__item-summary-files-item-image {
                  background-image: url("../images/icons/document-white.svg");
                }
              }
            }
          }

          &:nth-child(2) {
            a,
            .offer-details__item-summary-files-item-btn {
              .offer-details__item-summary-files-item-image {
                background-image: url("../images/icons/mail.svg");
              }

              &:hover {
                color: #fff;
                .offer-details__item-summary-files-item-image {
                  background-image: url("../images/icons/mail-white.svg");
                }
              }
            }
          }

          @media (min-width: 576px) {
            a,
            .offer-details__item-summary-files-item-btn {
              max-width: 250px;

              .offer-details__item-summary-files-item-image {
                margin-right: 20px;
              }

              .offer-details__item-summary-files-item-text {
                font-size: 13px;
              }
            }
          }

          @media (min-width: 768px) {
            margin-bottom: 50px;

            a,
            .offer-details__item-summary-files-item-btn {
              margin-left: 0;
              .offer-details__item-summary-files-item-image {
                margin-right: 30px;
                height: 40px;
                width: 40px;
              }

              .offer-details__item-summary-files-item-text {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}

.offer-loading {
  height: 100vh;
  text-align: center;
  padding-top: 150px;

  p {
    font-size: 1.2rem;
  }
}
