.popup {
  position: fixed;
  max-width: 95%;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-height: 98%;
  max-width: 98%;
  z-index: 999;
  background-color: #fff;
  padding: 0.25rem;

  @media (min-width: 550px) {
    max-width: 550px;
  }

  @media (min-width: 768px) {
    max-width: 768px;
  }

  @media (min-width: 1200px) {
    max-width: 1190px;
  }

  .popup__close {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 20px;
    height: 20px;
    background-color: transparent;
    font-size: 0;
    cursor: pointer;

    @media (min-width: 500px) {
      right: 13px;
      top: 13px;
    }

    @media (min-width: 768px) {
      right: 15px;
      top: 15px;
      width: 30px;
      height: 30px;
    }

    @media (min-width: 1200px) {
      right: 24px;
      top: 22px;
      width: 45px;
      height: 45px;
    }
  }
}
