.clickable-image {
   cursor: pointer;
}

.zoomed-image {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   z-index: 99999;

   .zoomed-image__bg {
      background-color: rgba(87, 86, 86, 0.74);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 99998;
   }

   .zoomed-image__box {
      max-width: 98%;
      max-height: 98%;
      top: 50%;
      left: 50%;
      width: 600px;
      height: 450px;
      transform: translate(-50%, -50%);
      position: absolute;
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
      z-index: 99999;
   }
}

@media (min-width: 1200px) {
   .zoomed-image {
      .zoomed-image__box {
         max-width: 90%;
         max-height: 90%;
         width: 1200px;
         height: 850px;
      }
   }
}
