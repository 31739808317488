.location {
   padding-top: 30px;
}

@media (min-width: 1200px) {
   .location {
      padding-top: 50px;

      .col--small {
         height: 280px;
      }
   }
}

@media (min-width: 1400px) {
   .location {
      .col--small {
         height: 248px;
      }
   }
}
