.investment {
   padding-top: 43px;

   .slider {
      .slider__container--investment[data-index="0"] {
         height: 250px;
      }

      .slider__container--investment[data-index="1"] {
         height: 220px;
      }
   }
}

@media (min-width: 550px) {
   .investment {
      .slider {
         .slider__container--investment[data-index="0"] {
            height: 165px;
         }

         .slider__container--investment[data-index="1"] {
            height: 135px;
         }
      }
   }
}

@media (min-width: 1200px) {
   .investment {
      padding-top: 82px;

      .slider {
         .slider__container--investment[data-index="0"] {
            height: 295px;
         }

         .slider__container--investment[data-index="1"] {
            height: 210px;
         }
      }
   }
}

@media (min-width: 1400px) {
   .investment {
      .slider {
         .slider__container--investment[data-index="0"] {
            height: 185px;
         }

         .slider__container--investment[data-index="1"] {
            height: 170px;
         }
      }
   }
}
