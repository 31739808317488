.tiles {
   padding-top: 43px;

   .tiles__wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
   }

   .tiles__item {
      position: relative;
      height: 138px;
      width: 150px;
      margin: 0 3px 16px;
      opacity: 0;
      background-image: url("../images/texture.jpg");
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: center;

      .tiles__item-image {
         img {
            width: 100%;
            height: 100%;
         }
      }

      .tiles__item-text {
         background: var(--s7-color-green);
         font-size: 10px;
         text-align: center;
         width: 100%;
         height: 100%;
         position: absolute;
         left: 0;
         top: 0;
         display: flex;
         justify-content: center;
         align-items: center;
         padding: 20px;
         text-transform: uppercase;
         line-height: 13px;
         opacity: 0;
         transition: 0.5s;
         font-family: "Limelight";
         pointer-events: none;
      }

      &:hover {
         .tiles__item-text {
            opacity: 1;
            pointer-events: all;
         }
      }
   }

   .tiles__item:nth-of-type(1) {
      transition: 0.3s;

      .tiles__item-image {
         max-width: 80px;
         width: 80px;
         min-width: 80px;
         max-height: 80px;
         height: 80px;
         min-height: 80px;
      }
   }
   .tiles__item:nth-of-type(2) {
      transition: 0.3s 0.3s;

      .tiles__item-image {
         margin-top: 20px;
         max-width: 120px;
         width: 120px;
         min-width: 120px;
         max-height: 120px;
         height: 120px;
         min-height: 120px;
      }
   }
   .tiles__item:nth-of-type(3) {
      transition: 0.3s 0.6s;

      .tiles__item-image {
         max-width: 80px;
         width: 80px;
         min-width: 80px;
         max-height: 80px;
         height: 80px;
         min-height: 80px;
      }
   }
   .tiles__item:nth-of-type(4) {
      transition: 0.3s 0.9s;

      .tiles__item-image {
         max-width: 100px;
         width: 100px;
         min-width: 100px;
         max-height: 100px;
         height: 100px;
         min-height: 100px;
      }
   }
   .tiles__item:nth-of-type(5) {
      transition: 0.3s 1.2s;
      .tiles__item-image {
         max-width: 100px;
         width: 100px;
         min-width: 100px;
         max-height: 100px;
         height: 100px;
         min-height: 100px;
      }
   }
   .tiles__item:nth-of-type(6) {
      transition: 0.3s 1.5s;

      .tiles__item-image {
         max-width: 80px;
         width: 80px;
         min-width: 80px;
         max-height: 80px;
         height: 80px;
         min-height: 80px;
      }
   }

   &.anim-text {
      .tiles__item {
         opacity: 1;
      }
   }
}

@media (min-width: 345px) {
   .tiles {
      .tiles__item {
         height: 150px;
         width: 165px;
      }
   }
}

@media (min-width: 680px) {
   .tiles {
      .tiles__item {
         height: 198px;
         width: 215px;

         .tiles__item-text {
            font-size: 14px;
            padding: 30px;
            line-height: 20px;
         }
      }

      .tiles__item:nth-of-type(1) {
         .tiles__item-image {
            max-width: 100px;
            width: 100px;
            min-width: 100px;
            max-height: 100px;
            height: 100px;
            min-height: 100px;
         }
      }
      .tiles__item:nth-of-type(2) {
         .tiles__item-image {
            margin-top: 30px;
            max-width: 150px;
            width: 150px;
            min-width: 150px;
            max-height: 150px;
            height: 150px;
            min-height: 150px;
         }
      }

      .tiles__item:nth-of-type(4) {
         .tiles__item-image {
            max-width: 130px;
            width: 130px;
            min-width: 130px;
            max-height: 130px;
            height: 130px;
            min-height: 130px;
         }
      }
      .tiles__item:nth-of-type(5) {
         .tiles__item-image {
            max-width: 120px;
            width: 120px;
            min-width: 120px;
            max-height: 120px;
            height: 120px;
            min-height: 120px;
         }
      }
      .tiles__item:nth-of-type(6) {
         .tiles__item-image {
            max-width: 90px;
            width: 90px;
            min-width: 90px;
            max-height: 90px;
            height: 90px;
            min-height: 90px;
         }
      }
   }
}

@media (min-width: 1200px) {
   .tiles {
      padding-top: 150px;

      .tiles__wrapper {
         justify-content: space-between;
      }

      .tiles__item {
         height: 275px;
         width: 300px;
         margin: 0;

         .tiles__item-text {
            font-size: 20px;
            padding: 40px;
            line-height: 24px;
         }

         &:nth-of-type(1),
         &:nth-of-type(2),
         &:nth-of-type(3) {
            margin: 0 0 45px;
         }
      }

      .tiles__item:nth-of-type(1) {
         .tiles__item-image {
            max-width: 150px;
            width: 150px;
            min-width: 150px;
            max-height: 150px;
            height: 150px;
            min-height: 150px;
         }
      }
      .tiles__item:nth-of-type(2) {
         .tiles__item-image {
            max-width: 210px;
            width: 210px;
            min-width: 210px;
            max-height: 210px;
            height: 210px;
            min-height: 210px;
         }
      }

      .tiles__item:nth-of-type(3) {
         .tiles__item-image {
            max-width: 135px;
            width: 135px;
            min-width: 135px;
            max-height: 135px;
            height: 135px;
            min-height: 135px;
         }
      }

      .tiles__item:nth-of-type(4) {
         .tiles__item-image {
            max-width: 190px;
            width: 190px;
            min-width: 190px;
            max-height: 190px;
            height: 190px;
            min-height: 190px;
         }
      }
      .tiles__item:nth-of-type(5) {
         .tiles__item-image {
            max-width: 170px;
            width: 170px;
            min-width: 170px;
            max-height: 170px;
            height: 170px;
            min-height: 170px;
         }
      }
      .tiles__item:nth-of-type(6) {
         .tiles__item-image {
            max-width: 135px;
            width: 135px;
            min-width: 135px;
            max-height: 135px;
            height: 135px;
            min-height: 135px;
         }
      }
   }
}

@media (min-width: 1400px) {
   .tiles {
      .tiles__item {
         height: 356px;
         width: 390px;

         &:nth-of-type(1),
         &:nth-of-type(2),
         &:nth-of-type(3) {
            margin: 0 0 40px;
         }

         .tiles__item-text {
            padding: 50px;
            line-height: 28px;
         }
      }

      .tiles__item:nth-of-type(1) {
         .tiles__item-image {
            max-width: 220px;
            width: 220px;
            min-width: 220px;
            max-height: 220px;
            height: 220px;
            min-height: 220px;
         }
      }
      .tiles__item:nth-of-type(2) {
         .tiles__item-image {
            max-width: 330px;
            width: 330px;
            min-width: 330px;
            max-height: 330px;
            height: 330px;
            min-height: 330px;
         }
      }

      .tiles__item:nth-of-type(3) {
         .tiles__item-image {
            max-width: 205px;
            width: 205px;
            min-width: 205px;
            max-height: 205px;
            height: 205px;
            min-height: 205px;
         }
      }

      .tiles__item:nth-of-type(4) {
         .tiles__item-image {
            max-width: 280px;
            width: 280px;
            min-width: 280px;
            max-height: 280px;
            height: 280px;
            min-height: 280px;
         }
      }
      .tiles__item:nth-of-type(5) {
         .tiles__item-image {
            max-width: 270px;
            width: 270px;
            min-width: 270px;
            max-height: 270px;
            height: 270px;
            min-height: 270px;
         }
      }
      .tiles__item:nth-of-type(6) {
         .tiles__item-image {
            max-width: 200px;
            width: 200px;
            min-width: 200px;
            max-height: 200px;
            height: 200px;
            min-height: 200px;
         }
      }
   }
}

@media (min-width: 1570px) {
   .tiles {
      .tiles__item {
         height: 390px;
         width: 427px;

         &:nth-of-type(1),
         &:nth-of-type(2),
         &:nth-of-type(3) {
            margin: 0 0 90px;
         }

         .tiles__item-text {
            padding: 80px;
         }
      }
   }
}

@media (max-width: 767px) {
   .tiles {
      .container {
         max-width: 100%;
         padding: 0;
         margin: 0;
      }
   }
}
