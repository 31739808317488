.video {
   .video__box {
      iframe {
         width: 100%;
         height: 210px;
      }
   }
}

@media (max-width: 767px) {
   .video {
      .container {
         max-width: 100%;
         margin: 0;
         padding: 0;

         .row {
            margin-right: 0;
            margin-left: 0;

            > .col,
            > [class*="col-"] {
               padding-right: 0;
               padding-left: 0;
            }
         }
      }
   }
}

@media (min-width: 550px) {
   .video {
      .video__box {
         iframe {
            height: 390px;
         }
      }
   }
}

@media (min-width: 1200px) {
   .video {
      .video__box {
         iframe {
            height: 550px;
         }
      }
   }
}

@media (min-width: 1400px) {
   .video {
      .video__box {
         iframe {
            height: 890px;
         }
      }
   }
}
