.map {
   padding-top: 40px;
   padding-bottom: 40px;

   .map__box {
      height: 240px;
      max-width: 360px;
      margin-left: auto;
      margin-right: auto;
      justify-content: center;
      overflow: hidden;

      .map__box-img {
         width: 80%;
         position: relative;
         margin-right: 16px;

         .line {
            width: 3px;
            left: auto;
            right: -10px;
            top: 0;
            height: 0;
         }
      }

      .map__box-legend {
         width: 20%;
      }
   }

   &.anim {
      .line {
         height: 100% !important;
      }
   }
}

@media (min-width: 768px) {
   .map {
      .map__box {
         height: 500px;
         max-width: 768px;
         .map__box-img {
            max-width: 100%;
         }
      }
   }
}

@media (min-width: 1200px) {
   .map {
      .map__box {
         height: 660px;
         max-width: 1010px;
         margin-bottom: 45px;
         margin-top: 45px;

         .map__box-img {
            margin-right: 50px;

            .line {
               width: 10px;
               right: -25px;
            }
         }
      }

      &.anim {
         .line {
            width: 10px;
            height: 100%;
         }
      }
   }
}

@media (min-width: 1400px) {
   .map {
      .map__box {
         height: 795px;
         max-width: 1209px;
      }
   }
}

@media (min-width: 1740px) {
   .map {
      .map__box {
         height: 1031px;
         max-width: 1578px;
      }
   }
}
