.no {
   padding-bottom: 40px;

   .no__title {
      margin-bottom: 25px;

      .no__title-logo {
         background-position: center center;
         background-size: contain;
         background-repeat: no-repeat;
         width: 130px;
         height: 50px;
         margin-left: auto;
         margin-right: auto;
      }
   }

   .no__text-box {
      text-align: center;
      padding-top: 20.5px;

      .no__text {
         font-size: 12px;
         margin-bottom: 20px;
      }

      .no__list {
         .no__list-item {
            font-size: 12px;
            line-height: 15px;
         }
      }
   }
}

@media (min-width: 1200px) {
   .no {
      padding-bottom: 70px;

      .no__title {
         margin-bottom: 0;
         padding-top: 60px;
         text-align: center;
         max-width: 300px;
         margin-left: auto;
         margin-right: 0;

         .no__title-logo {
            width: 100%;
            height: 65px;
            margin: 0;
         }
      }

      .no__text-box {
         text-align: left;
         padding-top: 0;
         flex-direction: column;
         align-items: flex-start;
         justify-content: center;
         padding-left: 96px;
         max-width: 874px;
         padding-right: 50px;

         .no__text {
            font-size: 18px;
            margin-bottom: 0;
         }

         .no__list {
            .no__list-item {
               font-size: 18px;
               line-height: 22px;
            }
         }
      }
   }
}

@media (min-width: 1400px) {
   .no {
      .no__title {
         margin-right: 95px;
      }

      .no__text-box {
         padding-right: 0px;
      }
   }
}
